import { useEffect, useState } from "react";

import * as yup from "yup";

import Logo from "assets/img/logo.svg";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { BiCheckCircle, BiError } from "react-icons/bi";
import { centeredContent, whiteBox } from "styles";

import { Label, TextInput, Button, Alert, Textarea } from "flowbite-react";
import { recoverPassword, updatePassword } from "services/auth.service";
import { useYupValidationResolver } from "services/utils";
import { ConfirmationModal } from "components/modal/confirmation";
import { getFaq } from "services/faq.service";
import Collapsible from "react-collapsible";

import "./styles.scss";
import { Helmet } from "react-helmet-async";
import { registerFeedbackMessage } from "services/feedback.service";
import { useAuth } from "context/AuthContext";
import { NavMenu } from "components/navmenu";
import { UserInfo } from "components/user-info";
import { useTranslation } from "react-i18next";
import { RegulamentoFooter } from "components/regulamento-footer";
import { maskPhone } from "utils/mask";

interface IFormInput {
  username: string;
  name: string;
  email: string;
  phone: string;
  code: string;
  message: string;
}

export function Contact() {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const [t] = useTranslation();

  const validateSchema = yup.object().shape({
    username: yup.string().required(`${t("username.required")}`),
    name: yup.string().required(`${t("name.required")}`),
    email: yup.string().required(`${t("email.required")}`),
    phone: yup.string().required(`${t("phone.required")}`),
    code: yup.string().required(`${t("code.required")}`),
    message: yup.string().required(`${t("message.required")}`),
  });

  const [validationSchema, setValidationSchema] = useState(validateSchema);

  const { register, setValue, handleSubmit } = useForm<IFormInput>({
    resolver: useYupValidationResolver(validationSchema),
    reValidateMode: "onChange",
  });

  const [submitLabel, setSubmitLabel] = useState("Enviar");
  const [errorMessage, setErrorMessage] = useState("");
  const [sendConfirmation, setSendConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [faq, setFaq] = useState<any>();

  async function onSubmit(data: IFormInput, e: any) {
    try {
      setLoading(true);
      setErrorMessage("");
      await registerFeedbackMessage(data);
      setSendConfirmation(true);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorMessage(error.message);
    }
  }

  function onSubmitError(error: any) {
    Object.keys(error).forEach((key) => {
      const errors = [];
      error[key].message && errors.push(error[key].message);
      setErrorMessage(errors.join(", "));
    });
  }

  useEffect(() => {
    if (user) {
      setValue("name", user.name);
      setValue("email", user.email ? user.email : "");
      setValue("username", user._id);
      setValue("code", user.extra.matricula);
    }

    render();

    async function render() {
      const data = await getFaq();
      setFaq(data);
    }
  }, [user, setValue]);

  function renderFaq() {
    return (
      <>
        {faq?.map((item: any, index: number) => (
          <Collapsible
            key={item._id}
            trigger={
              <span>
                <b>{item.order <= 9 ? "0" + item.order : item.order}</b>
                {item.question}
              </span>
            }
            transitionTime={200}
          >
            <p>{item.answer}</p>
          </Collapsible>
        ))}
      </>
    );
  }

  function renderForm() {
    return (
      <>
        <div className="bg-[#04227B] w-screen text-white md:-mx-10">
          <div className="md:w-[1200px] py-4 md:py-0 px-4 md:px-12  mx-auto flex flex-col md:flex-row">
            <div className="flex flex-col justify-center space-y-5 md:mr-16 md:pr-16">
              <h1 className="w-full font-bold leading-none text-3xl text-blue-dark">
                Precisa de ajuda? Conte com a gente!
              </h1>
              <p>
                Nosso atendimento está disponível para fornecer informações
                sobre a campanha, registrar sugestões, esclarecer dúvidas e
                atender pedidos sobre o sistema da campanha.
              </p>

              <p>Atendimento acontece nos dias úteis entre 8h e 18h.</p>

              <div className="text-xs pb-3 mt-10 text-left">
                <a
                  href="https://novo.brb.com.br/politica-de-privacidade/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Consulte nossa política de privacidade
                </a>
              </div>
            </div>

            <div className="md:min-w-[476px] pb-10 relative mt-10">
              <form
                className="w-full mt-2 space-y-4"
                onSubmit={handleSubmit(onSubmit, onSubmitError)}
              >
                <div className="space-y-3">
                  <div className="relative">
                    {/* <Label>CPF</Label> */}
                    <TextInput
                      {...register("username", {
                        onChange: (e) =>
                          (e.target.value = e.target.value.replace(/\D+/g, "")),
                      })}
                      id="username"
                      name="username"
                      type="text"
                      autoComplete="false"
                      placeholder="Digite seu CPF"
                      maxLength={11}
                      required
                      readOnly={!!user?._id}
                      onKeyUp={() => {
                        setErrorMessage("");
                      }}
                    />
                  </div>

                  <div className="relative">
                    {/* <Label>Matrícula</Label> */}
                    <TextInput
                      {...register("code", {
                        onChange: (e) =>
                          (e.target.value = e.target.value.toUpperCase()),
                      })}
                      id="code"
                      name="code"
                      type="text"
                      autoComplete="false"
                      required
                      placeholder="Digite sua Matrícula"
                      title="Matricula"
                      readOnly={!!user?.extra.matricula}
                      onKeyUp={() => {
                        setErrorMessage("");
                        // setShowPassword(false)
                      }}
                    />
                  </div>

                  <div className="relative">
                    {/* <Label>Nome</Label> */}
                    <TextInput
                      {...register("name", {
                        onChange: (e) =>
                          (e.target.value = e.target.value.trim()),
                      })}
                      id="name"
                      name="name"
                      type="text"
                      autoComplete="false"
                      placeholder="Digite seu Nome"
                      required
                      readOnly={!!user?.name}
                      onKeyUp={() => {
                        setErrorMessage("");
                      }}
                    />
                  </div>

                  <div className="relative">
                    {/* <Label>E-mail</Label> */}
                    <TextInput
                      {...register("email", {
                        onChange: (e) =>
                          (e.target.value = e.target.value.trim()),
                      })}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="false"
                      placeholder="Digite seu E-mail"
                      required
                      readOnly={!!user?.email}
                      onKeyUp={() => {
                        setErrorMessage("");
                      }}
                    />
                  </div>

                  <div className="relative">
                    {/* <Label>Telefone</Label> */}
                    <TextInput
                      {...register("phone", {
                        onChange: (e) =>
                          (e.target.value = maskPhone(e.target.value)),
                      })}
                      id="phone"
                      name="phone"
                      type="tel"
                      autoComplete="false"
                      placeholder="Digite seu Telefone"
                      required
                      onKeyUp={() => {
                        setErrorMessage("");
                      }}
                      helperText={
                        <>
                          <small className="font-medium text-white">
                            com DDD ex: (61) 99999-9999
                          </small>
                        </>
                      }
                      maxLength={14}
                    />
                  </div>

                  <div className="relative">
                    {/* <Label>Mensagem</Label> */}
                    <Textarea
                      {...register("message")}
                      id="message"
                      name="message"
                      autoComplete="false"
                      required
                      rows={3}
                      placeholder="Digite sua mensagem"
                      title="Mensagem"
                      onKeyUp={() => {
                        setErrorMessage("");
                        // setShowPassword(false)
                      }}
                    />
                  </div>
                </div>

                {!!errorMessage && (
                  <Alert icon={BiError} color={"failure"} className="pr-2">
                    {errorMessage}
                  </Alert>
                )}

                <div className="flex flex-col justify-center items-center space-y-1 btnEnviar">
                  <Button type="submit" disabled={loading} className="w-full">
                    {loading ? "Aguarde..." : submitLabel}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <ConfirmationModal
          show={sendConfirmation}
          title="Mensagem Enviada"
          message="A sua mensagem foi enviada com sucesso!"
          confirmLabel="Voltar"
          icon={<BiCheckCircle className="text-success w-full h-full" />}
          onConfirm={() => navigate(-1)}
        />
      </>
    );
  }

  if (isAuthenticated) {
    return (
      <>
        <Helmet>
          <title>Contato - VAI PRA CIMA, BRB!</title>
        </Helmet>
        <div id="home-wrapper" className="min-h-screen">
          <div className="z-10 relative flex flex-col items-center space-y-0 md:space-y-10 md:p-10">
            <NavMenu />

            <div className="w-full flex justify-center p-2 md:p-0">
              <UserInfo />
            </div>
            <div className="w-full max-w-[1100px]  contact-wrapper px-4 md:px-0">
              <h2 className="w-full font-bold leading-none text-3xl">Ajuda</h2>
              {renderFaq()}
            </div>
            <div className="w-full contact-wrapper">{renderForm()}</div>
          </div>
          <RegulamentoFooter />
        </div>
        <Outlet />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Contato - VAI PRA CIMA, BRB!</title>
      </Helmet>
      <div
        id="contact-wrapper"
        className="min-h-screen flex flex-col md:flex-row 2xl:items-center md:justify-center"
      >
        {/* <div className="m-3 bg-white p-3 rounded-xl md:m-0 md:bg-none md:rounded-none md:flex-1 flex items-center justify-center py-4 md:p-0 md:max-h-screen md:fixed md:top-0 md:left-[23vw] md:bottom-0">
          <img src={Logo} alt="Logo" className="h-24 md:h-[50vh]" />
        </div> */}
        <div className="min-h-screen">
          <div className="z-10 relative flex flex-col items-center space-y-0 md:space-y-10 md:p-10">
            <div className="w-full max-w-[1100px]  contact-wrapper px-4 md:px-0">
              <h2 className="w-full font-bold leading-none text-3xl">Ajuda</h2>
              {renderFaq()}
            </div>

            <div>
              {renderForm()}

              <div className="w-full py-2">{/* <hr /> */}</div>

              <div className="flex items-center justify-center text-xs pt-2 space-x-2">
                {/* <Link
              to=""
              onClick={() => navigate(-1)}
              className="ml-1 text-blue-500"
            >
              Voltar
            </Link> */}
                <button
                  type="button"
                  className="w-[200px] p-3 mb-3 text-blue-dark bg-white border border-transparent flex h-min items-center justify-center text-center font-medium rounded"
                  onClick={() => navigate(-1)}
                >
                  &lt;- VOLTAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
