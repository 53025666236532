import { Helmet } from 'react-helmet-async';

export default function Profile() {
  return (
    <>
      <Helmet>
        <title>Funifier Boilerplace - Profile</title>
      </Helmet>
      <div>
        <h1>PROFILE</h1>
      </div>
    </>
  );
}
