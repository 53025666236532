/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import * as yup from "yup";

import Logo from "assets/img/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { BiCheckCircle, BiError } from "react-icons/bi";
import { centeredContent, whiteBox } from "styles";

import { Label, TextInput, Button, Alert } from "flowbite-react";
import { recoverPassword, updatePassword } from "services/auth.service";
import { useYupValidationResolver } from "services/utils";
import { ConfirmationModal } from "components/modal/confirmation";

import "./styles.scss";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Tippy from "@tippyjs/react";

interface IFormInput {
  username: string;
  code: string;
  password: string;
  password_confirm: string;
}

export function ForgotPassword() {
  const [t] = useTranslation();

  const validateSchema = yup.object().shape({
    username: yup.string().required(`${t("username.required")}`),
  });

  const recoverySchema = yup.object().shape({
    username: yup.string().required(`${t("username.required")}`),
    code: yup.string().required(`${t("code.required")}`),
    password: yup
      .string()
      .min(8, `${t("password.short")}`)
      .matches(/^[^_.]/, 'Senha não pode começar com "_" ou "."')
      .matches(/[A-Z]/, "A senha deve conter pelo menos uma letra maiúscula")
      .matches(/[a-z]/, "A senha deve conter pelo menos uma letra minúscula")
      .matches(
        /[!@$%().?:{}<>]/,
        "A senha deve conter pelo menos um caractere especial"
      )
      .matches(/[0-9]/, "A senha deve conter pelo menos um número")
      .test(
        "no-invalid-chars",
        "A senha não pode conter caracteres inválidos, verifique os caracteres na ajuda",
        (value) => {
          if (!value) return true;
          const invalidChars = /[*;:_`'"&^#,|-]/;
          return !invalidChars.test(value);
        }
      )
      .required(`${t("password.required")}`),
    password_confirm: yup
      .string()
      .required(`${t("password.confirmation.required")}`)
      .oneOf([yup.ref("password")], `${t("password.match")}`),
  });

  const navigate = useNavigate();
  const [validationSchema, setValidationSchema] = useState(validateSchema);

  const { register, setValue, handleSubmit } = useForm<IFormInput>({
    resolver: useYupValidationResolver(validationSchema),
    reValidateMode: "onChange",
  });

  const [submitLabel, setSubmitLabel] = useState("Enviar");
  const [errorMessage, setErrorMessage] = useState("");
  const [recoverySent, setRecoverySent] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  async function onSubmit(data: IFormInput, e: any) {
    try {
      setLoading(true);
      setErrorMessage("");
      if (!recoverySent) {
        let userParse = parseInt(data.username);
        await recoverPassword(String(userParse));
        setRecoverySent(true);
      } else {
        let userParse = parseInt(data.username);
        data.username = String(userParse);
        const result = await updatePassword(data);
        setPasswordChanged(true);
      }
      setRecoverySent(true);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorMessage(error.message);
    }
  }

  function onSubmitError(error: any) {
    Object.keys(error).forEach((key) => {
      const errors = [];
      error[key].message && errors.push(error[key].message);
      setErrorMessage(errors.join(", "));
    });
  }

  useEffect(() => {
    if (recoverySent) {
      setValidationSchema(recoverySchema);
      setSubmitLabel("Alterar senha");
    }
  }, [recoverySent]);

  function dica() {
    return (
      <>
        <p className="font-bold mb-1">Dicas de senha</p>
        <ul className="list-disc p-5 ">
          <li>Deve ter pelo menos 8 caracteres </li>
          <li>A senha não pode começar com . ou _ </li>
          <li>Deve ter pelo menos uma letra maiúscula</li>
          <li>Deve ter pelo menos uma letra minúscula</li>
          <li>Deve ter pelo menos um caracter especial</li>
          <li>Não são permitidos os caracteres (* ; : _ ` ' " & ^ # , | -)</li>
          <li>Deve ter pelo menos um número </li>
        </ul>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Recuperar Senha - VAI PRA CIMA, BRB!</title>
      </Helmet>
      <div
        id="forgot-wrapper"
        className="min-h-screen flex flex-col md:flex-row 2xl:items-center md:justify-center"
      >
        {/* <div className="m-3 bg-white p-3 rounded-xl md:m-0 md:bg-none md:rounded-none md:flex-1 flex items-center justify-center py-4 md:p-0 md:max-h-screen md:fixed md:top-0 md:left-[23vw] md:bottom-0">
          <img src={Logo} alt="Logo" className="h-24 md:h-[50vh]" />
        </div> */}

        <div className={whiteBox}>
          <h1 className="w-full font-bold text-2xl 2xl:text-3xl text-blue-dark leading-tight">
            Esqueceu a senha? <br />A gente te ajuda!
          </h1>

          <form
            className="w-full mt-2 space-y-4"
            onSubmit={handleSubmit(onSubmit, onSubmitError)}
          >
            <p>Informe seu CPF e receba o código de segurança em seu e-mail</p>

            <div className="space-y-3">
              <div className="relative">
                <Label>CPF</Label>
                <TextInput
                  {...register("username", {
                    onChange: (e) =>
                      (e.target.value = e.target.value.replace(/\D+/g, "")),
                  })}
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="false"
                  placeholder="Digite seu CPF"
                  maxLength={11}
                  required
                  onKeyUp={() => {
                    setErrorMessage("");
                  }}
                />
              </div>
            </div>

            {recoverySent ? (
              <>
                <div className="relative">
                  <Label htmlFor="code">Código de Recuperação</Label>
                  <TextInput
                    {...register("code")}
                    id="code"
                    name="code"
                    type="text"
                    autoComplete="false"
                    placeholder="Digite o código de recuperação"
                    required
                  />
                </div>

                <div>
                  <Label htmlFor="password">
                    Nova Senha{" "}
                    <Tippy
                      arrow={false}
                      className="hidden lg:block bg-blue-light drop-shadow-lg p-4"
                      allowHTML={true}
                      touch={"hold"}
                      content={dica()}
                    >
                      <span className="ml-2 bg-[#0fbbf1] text-white rounded-full px-1">
                        ?
                      </span>
                    </Tippy>
                  </Label>
                  <TextInput
                    {...register("password")}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="false"
                    placeholder="Digite a nova senha"
                    required
                  />
                </div>

                <div>
                  <Label htmlFor="password_confirm">Confirmação de Senha</Label>
                  <TextInput
                    {...register("password_confirm")}
                    id="password_confirm"
                    name="password_confirm"
                    type="password"
                    autoComplete="false"
                    placeholder="Digite novamente a senha"
                    required
                  />
                </div>
              </>
            ) : null}

            {!!errorMessage && (
              <Alert icon={BiError} color={"failure"} className="pr-2">
                {errorMessage}
              </Alert>
            )}

            <div className="flex flex-col justify-center items-center space-y-1 pt-2">
              <Button type="submit" disabled={loading} className="w-full">
                {loading ? "Aguarde..." : submitLabel}
              </Button>
            </div>

            <div className="flex items-center justify-center text-xs pt-2 space-x-2">
              <Link to="/login" className="ml-1 text-blue-500">
                Voltar para Login
              </Link>
            </div>
          </form>
        </div>
      </div>
      <ConfirmationModal
        show={passwordChanged}
        title="Alteração de senha"
        message="Sua senha foi alterada com sucesso! Faça login para continuar."
        confirmLabel="Voltar para Login"
        icon={<BiCheckCircle className="text-success w-full h-full" />}
        onConfirm={() => navigate("/")}
      />
    </>
  );
}
