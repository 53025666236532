import { api } from "./api";

export async function getContent() {
  const payload = [{ $sort: { created: -1 } }, { $limit: 1 }] as any;

  payload.unshift({
    $match: {
      active: true,
    },
  });


  const { data } = await api.post(`database/dicas_do_juka__c/aggregate`, payload);
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}


export async function getAllDicas() {
  const payload = [{ $sort: { created: -1 } }] as any;

  const { data } = await api.post(`database/dicas_do_juka__c/aggregate`, payload);
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}