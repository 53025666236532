// import { User } from '@/context/AuthContext'
import { getISODay, addDays } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useCallback } from 'react';
import * as yup from 'yup';

// export function getTotalPointsByType(user: User, pointType: string) {
// 	try {
// 		return user.point_categories[pointType]
// 	} catch {
// 		return 0
// 	}
// }

export function getClosestDaysOfNextWeek(dayOfWeek: number, fromDate = new Date()) {
  // Mon: 1, Tue: 2, Wed: 3, Thur: 4, Fri: 5, Sat: 6, Sun: 7
  const dayOfWeekMap = [0, 1, 2, 3, 4, 5, 6];

  // Converte hora local para PST
  const zonedFrom = utcToZonedTime(fromDate.toISOString(), `America/Los_Angeles`);

  // Horário local da máquina do usuario
  const localFrom = zonedTimeToUtc(zonedFrom, `America/Los_Angeles`);

  // Horário do Brasil
  const brazilFrom = utcToZonedTime(fromDate.toISOString(), `America/Sao_Paulo`);

  /*console.log('UTC')
	console.log(fromDate.toUTCString())
	console.log(fromDate.toISOString())
	console.log('\nPST')
	console.log(zonedFrom.toUTCString())
	console.log(zonedFrom.toISOString())
	console.log(zonedFrom.toLocaleString())
	console.log('\nBRAZIL')
	console.log(brazilFrom.toUTCString())
	console.log(brazilFrom.toISOString())
	console.log(brazilFrom.toLocaleString())
	console.log('\nLOCAL')
	console.log(localFrom.toUTCString())
	console.log(localFrom.toISOString())
	console.log(localFrom.toLocaleString())*/

  const offsetDaysToFirstDate = 7 - (getISODay(localFrom) - dayOfWeekMap[dayOfWeek]);
  const offsetDaysToSecondDate = 14 - (getISODay(localFrom) - dayOfWeekMap[dayOfWeek]);

  const firstDayOfWeek = addDays(localFrom, offsetDaysToFirstDate);
  const secondDayOfWeek = addDays(localFrom, offsetDaysToSecondDate);

  // return [ format(firstDayOfWeek, 'EEE, MM-dd-yyyy'), format(secondDayOfWeek, 'EEE, MM-dd-yyyy')]
  return [firstDayOfWeek, secondDayOfWeek];
}

export const useYupValidationResolver = (validationSchema: yup.AnyObject) =>
  useCallback(
    async (data: any) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors: any) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors: any, currentError: { path: any; type: any; message: any }) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );
