/* eslint-disable react-hooks/exhaustive-deps */

import { useAuth } from "context/AuthContext";
import { Outlet } from "react-router";
import { UserInfo } from "components/user-info";
import { DetailChallengeModal } from "./Components/modal-detail";
import { RegulamentoFooter } from "components/regulamento-footer";

import ChallengeCard from "./Components/challenge-card";

import "./styles.scss";
import { NavLink } from "react-router-dom";
import { NavMenu } from "components/navmenu";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useModal } from "context/ModalContext";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  getChallenges,
  getAllChallenges,
  getChallengesLimit,
  getMyChallenges,
} from "services/challenges.service";
import moment from "moment";
import "moment/locale/pt-br";

moment.locale("pt-br");

export function Challenges() {
  const { user } = useAuth();
  const { showModal } = useModal();
  const [challenges, setChallenges] = useState<any>();
  const [challengesExpired, setChallengesExpired] = useState<any>();
  const comparaData = new Date().getTime();
  const [loading, setLoading] = useState<boolean>(false);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4.5,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3.2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  useEffect(() => {
    setLoading(true);
    loadChallenges();
  }, [user]);

  async function loadChallenges() {
    const data = await getMyChallenges("GT35", user?._id);

    setChallenges(
      data.filter((item: any) => {
        return item.vigente;
      })
    );
    setChallengesExpired(
      data.filter((item: any) => {
        return item.vigente === false;
      })
    );

    setLoading(false);
  }

  if (!user) return null;

  return (
    <>
      <Helmet>
        <title>VAI PRA CIMA, BRB!</title>
      </Helmet>
      <div id="home-wrapper" className="min-h-screen pb-16 md:pb-0">
        <div
          className="z-10 relative flex flex-col items-center space-y-0 md:space-y-10 md:p-10"
          id="pageChallenges"
        >
          <NavMenu />

          <div className="w-full flex justify-center p-2 md:p-0">
            <UserInfo />
          </div>

          <div className="bg-[#0938A3] w-screen text-white md:-mx-10 bgShadow">
            <div className="w-full md:w-[1200px] mx-auto  p-5 md:p-8">
              <h2>
                Desafios <strong>vigentes</strong>
              </h2>
              {loading && (
                <p className="text-center noChallenges">Carregando...</p>
              )}
              {!loading && challenges && (
                <Carousel
                  responsive={responsive}
                  className="mr-[-26px] md:mr-0"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                >
                  {challenges.map((item: any) => (
                    <ChallengeCard data={item} />
                  ))}
                </Carousel>
              )}
              {!loading && !challenges && (
                <p className="text-center noChallenges">
                  Sem novos desafios no momento
                </p>
              )}
            </div>
          </div>

          <div className="w-screen text-white md:-mx-10 ">
            <div className="w-full md:w-[1200px] mx-auto  p-5 md:p-8">
              <h2>
                Desafios <strong>expirados</strong>
              </h2>
              {loading && (
                <p className="text-center noChallenges">Carregando...</p>
              )}
              {!loading && challengesExpired && (
                <Carousel
                  responsive={responsive}
                  className="mr-[-26px] md:mr-0"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                >
                  {challengesExpired
                    .slice()
                    .sort((a: any, b: any) => {
                      if (a.apurado && !b.apurado) {
                        return -1;
                      } else if (!a.apurado && b.apurado) {
                        return 1;
                      } else if (a.isApurado && !b.isApurado) {
                        return -1;
                      } else if (!a.isApurado && b.isApurado) {
                        return 1;
                      } else {
                        return 0;
                      }
                    })
                    .map((item: any) => (
                      <ChallengeCard data={item} />
                    ))}
                </Carousel>
              )}
              {!loading && !challengesExpired && (
                <p className="text-center noChallenges">
                  Sem desafios expidados
                </p>
              )}
            </div>
          </div>
        </div>
        <RegulamentoFooter />
      </div>
      <Outlet />
      <DetailChallengeModal />
    </>
  );
}
