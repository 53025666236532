import { api } from "./api";

export interface IPrizes {
  _id: string;
  player: string;
  name: string;
  catalogId: string;
  description: string;
  image: string;
}

export async function getVirtualGoods() {
  try {
    const { data } = await api.get("virtualgoods/item?max_results=10000");
    return data;
  } catch (error) {
    return error;
  }
}

export async function getExperienceCatalog() {
  try {
    const { data } = await api.get("database/experiencias__c");
    return data;
  } catch (error) {
    return error;
  }
}

export async function getVirtualGood(_id: string) {
  try {
    const { data } = await api.get(`virtualgoods/item/${_id}`);
    return data;
  } catch (error) {
    return error;
  }
}

export async function purchaseItem(item: string, player: string) {
  try {
    const { data } = await api.post("virtualgoods/purchase", {
      item,
      player,
    });
    return data;
  } catch (error) {
    return error;
  }
}

export async function getPrizes(player: string, unidade: string) {
  try {
    const { data } = await api.post("find/prizes", {
      player,
      unidade,
    });
    return data as IPrizes[];
  } catch (error) {
    return error;
  }
}

export async function getListaResgates(startDate: string, endDate: string) {
  const payload = { startDate, endDate };

  const { data } = await api.post("/find/resgates_itens", payload, {
    headers: {
      range: "items=0-10000",
    }
  });
  return data;
}

export async function getCardsId() {
  const payload = [
    {
      "$match": {
        "catalogId": {
          "$in": [
            "cards",
            "cards_gemac"
          ]
        }
      }
    },
    {
      "$group": {
        "_id": null,
        "ids": {
          "$push": "$_id"
        }
      }
    }
  ];

  const { data } = await api.post("/database/catalog_item/aggregate", payload);
  return data;
}


export async function getTransparencia() {
  const payload = [{ $sort:{ "created": -1} }];

  const { data } = await api.post("/database/transparencia_loja__c/aggregate", payload);
  return data;
}