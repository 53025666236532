import { api } from "./api";

export async function getTeams() {
	try {
		const { data } = await api.get(`team`, {
			params: { orderby: "extra.name" },
		});
		return data;
	} catch (e) {
		throw e;
	}
}

export async function getTeamsWithMembers() {
	try {
		const payload: any = [
			{
				$lookup: {
					from: "team_player",
					localField: "_id",
					foreignField: "teamId",
					as: "members",
				},
			},
			{
				$set: {
					members: {
						$size: "$members",
					},
				},
			},
		];
		const { data } = await api.post(`database/team/aggregate`, payload);
		return data;
	} catch (e) {
		throw e;
	}
}

export async function getTeamById(teamId: string) {
	try {
		if (!teamId) return;
		const { data } = await api.get(`team/${teamId}`);
		return data;
	} catch (e) {
		throw e;
	}
}

export async function getTeamStatusById(teamId: string) {
	try {
		if (!teamId) return;
		const payload = [
			{
				$match: {
					_id: teamId,
				},
			},
			{
				$lookup: {
					from: "team_player",
					localField: "_id",
					foreignField: "teamId",
					as: "members",
				},
			},
			{
				$lookup: {
					from: "player",
					localField: "owner",
					foreignField: "_id",
					as: "owners",
				},
			},
			{
				$set: {
					members: {
						$size: "$members",
					},
					active: 1,
					owner_name: {
						$max: "$owners.name",
					},
					owner_image: {
						$max: "$owners.image",
					},
				},
			},
			{
				$project: {
					members: 1,
					active: 1,
					name: 1,
					image: 1,
					owner: 1,
					owner_name: 1,
					owner_image: 1,
				},
			},
		];
		const { data } = await api.post(`database/team/aggregate`, payload);
		return data[0];
	} catch (e) {
		throw e;
	}
}

export async function getTeamMembers(teamId: string) {
	try {
		if (!teamId) return;
		const { data } = await api.get(`team/${teamId}/member`);
		return data;
	} catch (e) {
		throw e;
	}
}

export async function getTeamMembersId(teamId: string) {
	try {
		if (!teamId) return;
		const { data } = await api.get(`team/${teamId}/memberids`);
		return data;
	} catch (e) {
		throw e;
	}
}

export async function joinTeam(playerId: string, teamId: string) {
	try {
		const { data } = await api.get(`player/${playerId}/team/join/${teamId}`);
		return data;
	} catch (e) {
		throw e;
	}
}

export async function leaveTeam(playerId: string, teamId: string) {
	try {
		const { data } = await api.get(`player/${playerId}/team/unjoin/${teamId}`);
		return data;
	} catch (e) {
		throw e;
	}
}

export async function createTeam(payload: any) {
	try {
		const { data } = await api.post(`team`,payload);
		return data;
	} catch (e) {
		throw e;
	}
}
