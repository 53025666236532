/* eslint-disable react-hooks/exhaustive-deps */

import { useAuth } from "context/AuthContext";
import { Outlet } from "react-router";
import { useNavigate } from "react-router-dom";
import { UserInfo } from "components/user-info";

import "./styles.scss";
import { NavLink } from "react-router-dom";
import { NavMenu } from "components/navmenu";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useModal } from "context/ModalContext";
import { getCampanha } from "services/campanha.service";
import { RegulamentoFooter } from "components/regulamento-footer";

export function HomeLogin() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { showModal } = useModal();
  const [content, setContent] = useState<any>();

  useEffect(() => {
    if (user) {
      if (user.extra.firstLogin === undefined || user.extra.firstLogin === true) {
        // loadData();
        navigate('/campanha');
      } else {
        navigate("/inicio");
      }
    } else {
      navigate("/inicio");
    }
  }, []);

  async function loadData() {
    const data = await getCampanha();
    setContent(data[0]);
  }

  if (!user) return null;

  return (
    <>
      <Helmet>
        <title>VAI PRA CIMA, BRB!</title>
      </Helmet>
      <div id="home-wrapper" className="min-h-screen">
        <div className="z-10 relative flex flex-col items-center space-y-0 md:space-y-10 md:p-10">
          <NavMenu />

          <UserInfo />
          <div className="md:w-[1200px]" id="homeLogin">
            <div className="p-8">
              {content && (
                <>
                  <h2 dangerouslySetInnerHTML={{ __html: content.title }}></h2>
                  <div
                    className="w-full"
                    dangerouslySetInnerHTML={{ __html: content.html }}
                  ></div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Outlet />
      <RegulamentoFooter />
    </>
  );
}
