/* eslint-disable react-hooks/exhaustive-deps */

import { useAuth } from "context/AuthContext";
import { Outlet } from "react-router";
import { UserInfo } from "components/user-info";

import "./styles.scss";
import { NavLink } from "react-router-dom";
import { NavMenu } from "components/navmenu";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useModal } from "context/ModalContext";
import { RegulamentoFooter } from "components/regulamento-footer";

import LogoFoguete from "assets/img/logoComFoguete.png";
import Desafios1 from "assets/img/ABA-1-desafios_semanais_1.png";
import Desafios1_1 from "assets/img/ABA-1-desafios_semanais_2.png";
import Desafios1_2 from "assets/img/ABA-1-desafios_semanais_3.png";
import Premios from "assets/img/ABA-2-premios_instantaneos.png";
import Turbo from "assets/img/ABA-3-desafio_turbo.png";
import Premiados from "assets/img/ABA-4-premiados.png";
import Premiados2 from "assets/img/ABA-4-premiados2.png";
import RecordistasImg from "assets/img/recordistas.jpg";

export function Recordistas() {
  const { user } = useAuth();
  const { showModal } = useModal();
  const [content, setContent] = useState<any>();
  const [tab, setShowTab] = useState<number>(1);

  useEffect(() => {
    if (user) {
    }
  }, [user]);

  if (!user) return null;

  return (
    <>
      <Helmet>
        <title>VAI PRA CIMA, BRB!</title>
      </Helmet>
      <div id="home-wrapper" className="min-h-screen">
        <div className="z-10 relative flex flex-col items-center space-y-0 md:space-y-10 md:pt-10">
          <NavMenu />

          <div className="w-full flex justify-center p-2 md:p-0">
            <UserInfo />
          </div>
          <div className="w-screen" id="objetivo">
            <div className="">
              <section id="main_inicio">
                <div className="container-lg">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="custom-container">
                        <div className="header_formas_ganhar">
                          {/* <h1 className="chamadaPrincipalInterna">
                            <strong>RECORDISTAS</strong>
                          </h1> */}
                          <p className="text-white text-[25px] tracking-wide">
                            CONHEÇA QUEM ESTA <b>SUPERANDO</b> TODOS OS{" "}
                            <b>MARCOS!</b>
                          </p>
                        </div>
                      </div>
                      <section id="tabsFormas">
                        <div className="custom-container">
                          <ul
                            className="nav nav-tabs nav-fill"
                            id="myTab"
                            role="tablist"
                          >
                            <li
                              className={`nav-item ${
                                tab === 1 ? "active" : ""
                              }`}
                              role="presentation"
                            >
                              <button
                                className="nav-link active"
                                id="bateulevou-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#bateulevou"
                                type="button"
                                role="tab"
                                aria-controls="bateulevou"
                                aria-selected="true"
                                onClick={() => {
                                  setShowTab(1);
                                }}
                              >
                                ANUAL
                              </button>
                            </li>
                            <li
                              className={`nav-item ${
                                tab === 2 ? "active" : ""
                              }`}
                              role="presentation"
                            >
                              <button
                                className="nav-link"
                                id="regularidade-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#regularidade"
                                type="button"
                                role="tab"
                                aria-controls="regularidade"
                                aria-selected="false"
                                onClick={() => {
                                  setShowTab(2);
                                }}
                              >
                                1º TRI
                              </button>
                            </li>
                            <li
                              className={`nav-item ${
                                tab === 3 ? "active" : ""
                              }`}
                              role="presentation"
                            >
                              <button
                                className="nav-link"
                                id="superacao-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#superacao"
                                type="button"
                                role="tab"
                                aria-controls="superacao"
                                aria-selected="false"
                                onClick={() => {
                                  setShowTab(3);
                                }}
                              >
                                2º TRI
                              </button>
                            </li>
                            <li
                              className={`nav-item ${
                                tab === 4 ? "active" : ""
                              }`}
                              role="presentation"
                            >
                              <button
                                className="nav-link"
                                id="desafios-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#desafios"
                                type="button"
                                role="tab"
                                aria-controls="desafios"
                                aria-selected="false"
                                onClick={() => {
                                  setShowTab(4);
                                }}
                              >
                                3º TRI
                              </button>
                            </li>
                            <li
                              className={`nav-item ${
                                tab === 5 ? "active" : ""
                              }`}
                              role="presentation"
                            >
                              <button
                                className="nav-link"
                                id="desafios-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#desafios"
                                type="button"
                                role="tab"
                                aria-controls="desafios"
                                aria-selected="false"
                                onClick={() => {
                                  setShowTab(5);
                                }}
                              >
                                4º TRI
                              </button>
                            </li>
                          </ul>
                          <div
                            className="tab-content w-full"
                            id="myTabContentRec"
                          >
                            <div
                              className={`tab-pane fade ${
                                tab === 1 ? "show active" : ""
                              }`}
                              role="tabpanel"
                              aria-labelledby="bateulevou-tab"
                            >
                              <img src={RecordistasImg} alt="recordistas" />{" "}
                              <br />
                              <br />
                            </div>
                            <div
                              className={`tab-pane fade ${
                                tab === 2 ? "show active" : ""
                              }`}
                              role="tabpanel"
                              aria-labelledby="regularidade-tab"
                            >
                              <h2 className="text-center text-[#09236b]">
                                Em breve você conhecerá os grandes recordistas
                                do período
                              </h2>
                            </div>
                            <div
                              className={`tab-pane fade ${
                                tab === 3 ? "show active" : ""
                              }`}
                              role="tabpanel"
                              aria-labelledby="superacao-tab"
                            >
                              <h2 className="text-center text-[#09236b]">
                                Em breve você conhecerá os grandes recordistas
                                do período
                              </h2>
                            </div>
                            <div
                              className={`tab-pane fade ${
                                tab === 4 ? "show active" : ""
                              }`}
                              role="tabpanel"
                              aria-labelledby="desafios-tab"
                            >
                              <h2 className="text-center text-[#09236b]">
                                Em breve você conhecerá os grandes recordistas
                                do período
                              </h2>
                            </div>

                            <div
                              className={`tab-pane fade ${
                                tab === 5 ? "show active" : ""
                              }`}
                              role="tabpanel"
                              aria-labelledby="desafios-tab"
                            >
                              <h2 className="text-center text-[#09236b]">
                                Em breve você conhecerá os grandes recordistas
                                do período
                              </h2>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div className="bg-[#081c5a]">
          <RegulamentoFooter />
        </div>
      </div>
      <Outlet />
    </>
  );
}
