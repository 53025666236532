import { api } from "./api";

export async function getChallenges(filters?: any) {
  const { data } = await api.get(`challenge`, {
    params: {
      orderby: "extra.order",
      q: `'extra.hidden':null,active:true${filters ? "," + filters : ""}`,
    },
  });
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data.filter((item) => item.techniques.includes("GT35"));
  }
}

export async function getAllChallenges(filters?: any) {
  const { data } = await api.get(`challenge`, {
    params: {
      orderby: "extra.order",
      reverse: true,
      q: "'extra.hidden':null,active:true",
    },
  });
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

export async function getChallengesLimit(limit: number) {
  const payload = [
    { $match: { active: true, "extra.hidden": null } },
    { $limit: limit },
  ] as any;

  const { data } = await api.post("/database/challenge/aggregate", payload);

  return data;
}

export async function getMyChallenges(
  technique: string,
  player?: any,
  challenge?: any
) {
  const payload = {
    technique: technique,
    player: player,
    challenge,
  };

  const { data } = await api.post(`/find/meus_desafios`, payload);

  return data;
}

export async function getChallengeById(id?: string) {
  const { data } = await api.get(`challenge`, {
    params: {
      orderby: "extra.order",
      q: `'_id':'${id}'`,
    },
  });
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data.filter((item) => item.techniques.includes("GT35"));
  }
}
