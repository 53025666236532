/* eslint-disable react-hooks/exhaustive-deps */

import { useAuth } from "context/AuthContext";
import { Outlet } from "react-router";
import { UserInfo } from "components/user-info";

import "./styles.scss";
import { NavLink } from "react-router-dom";
import { NavMenu } from "components/navmenu";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useModal } from "context/ModalContext";
import { RegulamentoFooter } from "components/regulamento-footer";
import { getEntregas, updateAchievement } from "services/achievement.service";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/pt-br";

moment.locale("pt-br");

export function MinhasEntregas() {
  const { user } = useAuth();
  const { showModal } = useModal();
  const [hallFama, setHallFama] = useState<any>();

  const [entregas, setEntregas] = useState<any>([]);

  useEffect(() => {
    if (user) {
      loadData();
    }
  }, [user]);

  async function loadData() {
    const data = await getEntregas(user?._id);
    setEntregas(data);
  }

  const confirmDelivery = (item: any) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Confirmar a entrega do item: " + item.catalogItem.name + " ?")) {
      item.extra.dataEntrega = new Date().getTime();
      item.extra.entregue = true;

      updateAchievement(item).then(function (res) {
        if (res) {
          toast(`👍 Obrigado por confirmar a entrega.`);
          loadData();
        } else {
          toast(
            `❌ Erro ao confirmar a entrega, por favor tente novamente mais tarde.`
          );
        }
      });
    }
  };

  const verifyIsConfirm = (item: any) => {
    const dataEnvio =
      new Date(item.time.$date).getTime() + 1000 * 60 * 60 * 24 * 2;
    const dataAtual = new Date().getTime();

    return dataAtual > dataEnvio;
  };

  if (!user) return null;

  return (
    <>
      <Helmet>
        <title>VAI PRA CIMA, BRB!</title>
      </Helmet>
      <div id="home-wrapper" className="min-h-screen">
        <div className="z-10 relative flex flex-col items-center space-y-0 md:space-y-10 md:pt-10">
          <NavMenu />

          <div className="w-full flex justify-center p-2 md:p-0">
            <UserInfo />
          </div>
          <div className="w-screen">
            <div className="" id="pageMinhasEntregas">
              <div className="flex justify-center p-2 md:p-0 md:pt-10">
                <div className="w-full md:w-[1200px]">
                  <h2>
                    Minhas <strong>entregas</strong>
                  </h2>
                  <table className="table mb-[100px]">
                    <thead>
                      <tr>
                        <th>ITEM</th>
                        <th>DATA ENVIO</th>
                        <th>STATUS</th>
                        <th className="text-right">OPÇÕES</th>
                      </tr>
                    </thead>
                    <tbody>
                      {entregas.map((item: any) => (
                        <tr key={item._id}>
                          <td>{item.catalogItem.name}</td>
                          <td>
                            {moment(item.time.$date).format("DD/MM/YYYY")}
                          </td>
                          <td>
                            {item.extra && !item.extra.dataEntrega ? (
                              <>
                                {item.extra.entregue ? (
                                  <p>Pedido entregue</p>
                                ) : (
                                  <p>A Caminho</p>
                                )}
                              </>                              
                            ) : (
                              <p>Entregue</p>
                            )}
                          </td>
                          <td className="text-right">
                            {item.extra && !item.extra.dataEntrega ? (
                              <>
                                {verifyIsConfirm(item) ? (
                                  <button
                                    className="bg-[#88E1F2] text-[#04227C]  p-4 font-bold uppercase"
                                    onClick={() => {
                                      confirmDelivery(item);
                                    }}
                                  >
                                    CONFIRMAR ENTREGA
                                  </button>
                                ) : (
                                  <p>
                                    Prevista para:{" "}
                                    {moment(item.extra.dataShowMessage.$date).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                )}
                              </>
                            ) : (
                              <p>
                                Entrega realizada dia:{" "}
                                {moment(item.extra.dataEntrega.$date).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <ul>
                    {entregas.map((item: any) => (
                      <li key={item._id}>{}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#081c5a]">
          <RegulamentoFooter />
        </div>
      </div>
      <Outlet />
    </>
  );
}
