/**
 * Composing <Route> in React Router v6
 * https://gist.github.com/mjackson/d54b40a094277b7afdd6b81f51a0393f
 *
 * Upgrading from v5
 * https://reactrouter.com/docs/en/v6/upgrading/v5
 */
import { Routes, Route } from "react-router-dom";

import { Home } from "pages/Home";
import { HomeLogin } from "pages/HomeLogin";
import { Login } from "pages/Auth/Login";
import { NotFound } from "pages/NotFound";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import Faq from "pages/Faq";
import Terms from "pages/Terms";
import Profile from "pages/Profile";
import { Register } from "pages/Auth/Register";
import { ForgotPassword } from "pages/Auth/ForgotPassword";
import { Challenges } from "pages/Challenges";
import Achievements from "pages/Achievements";
import { Contact } from "pages/Contact";
import { MixedRoute } from "./MixedRoute";
import { Objetivos } from "pages/Objetivos";
import { Premios } from "pages/Premios";
import { Extrato } from "pages/Extrato";
import { Campanha } from "pages/Campanha";
import { ComoGanhar } from "pages/ComoGanhar";
import { Experiencias } from "pages/Experiencias";
import { Convencao } from "pages/Convencao";
import { ChallengesResults } from "pages/ChallengesResults";
import { Resgates } from "pages/Resgates";
import { Aniversario } from "pages/Aniversario57Anos";
import { RetaFinal } from "pages/RetaFinal";
import { QuadroMedalhas } from "pages/QuadroMedalhas";
import { Recordistas } from "pages/Recordistas";
import { MinhasEntregas } from "pages/MinhasEntregas";

export const RouteList = () => (
  <Routes>
    <Route
      path="/"
      element={
        <PrivateRoute redirectTo="/login">
          <HomeLogin />
        </PrivateRoute>
      }
    />

    <Route
      path="/inicio"
      element={
        <PrivateRoute redirectTo="/login">
          <Home />
        </PrivateRoute>
      }
    />

    <Route
      path="/perfil"
      element={
        <PrivateRoute redirectTo="/login">
          <Profile />
        </PrivateRoute>
      }
    />

    <Route
      path="/desafios"
      element={
        <PrivateRoute redirectTo="/login">
          <Challenges />
        </PrivateRoute>
      }
    />

    <Route
      path="/desafios/apuracao"
      element={
        <PrivateRoute redirectTo="/login">
          <ChallengesResults />
        </PrivateRoute>
      }
    />

    <Route
      path="/objetivos"
      element={
        <PrivateRoute redirectTo="/login">
          <Objetivos />
        </PrivateRoute>
      }
    />

    <Route
      path="/premios"
      element={
        <PrivateRoute redirectTo="/login">
          <Premios />
          {/* <NotFound /> */}
        </PrivateRoute>
      }
    />

    <Route
      path="/experiencias"
      element={
        <PrivateRoute redirectTo="/login">
          <Experiencias />
        </PrivateRoute>
      }
    />

    <Route
      path="/convencao"
      element={
        <PrivateRoute redirectTo="/login">
          {/* <Convencao /> */}
          <NotFound />
        </PrivateRoute>
      }
    />

    <Route
      path="/extrato"
      element={
        <PrivateRoute redirectTo="/login">
          <Extrato />
        </PrivateRoute>
      }
    />

    <Route
      path="/ganhadores"
      element={
        <PrivateRoute redirectTo="/login">
          <Achievements />
        </PrivateRoute>
      }
    />

    <Route
      path="/campanha"
      element={
        <PrivateRoute redirectTo="/login">
          <Campanha />
        </PrivateRoute>
      }
    />

    <Route
      path="/reta-final"
      element={
        <PrivateRoute redirectTo="/login">
          {/* <RetaFinal /> */}
          <NotFound />
        </PrivateRoute>
      }
    />    

    <Route
      path="/minhas-entregas"
      element={
        <PrivateRoute redirectTo="/login">
          <MinhasEntregas />
        </PrivateRoute>
      }
    />

    <Route
      path="/como-ganhar"
      element={
        <PrivateRoute redirectTo="/login">
          <ComoGanhar />
        </PrivateRoute>
      }
    />

    {/* <Route
      path="/resgates"
      element={
        <PrivateRoute redirectTo="/login">
          <Resgates />
        </PrivateRoute>
      }
    /> */}

    <Route
      path="/aniversario"
      element={
        <PrivateRoute redirectTo="/login">
          {/* <Aniversario /> */}
          <NotFound />
        </PrivateRoute>
      }
    />

    <Route path="/faq" element={<Faq />} />

    <Route path="/regulamento" element={<Terms />} />

    <Route path="/contato" element={<Contact />} />

    <Route
      path="/login"
      element={
        <PublicRoute>
          <Login />
        </PublicRoute>
      }
    />

    <Route
      path="/register"
      element={
        <PublicRoute>
          <Register />
        </PublicRoute>
      }
    />

    <Route
      path="/forgot-password"
      element={
        <PublicRoute>
          <ForgotPassword />
        </PublicRoute>
      }
    />

    <Route
      path="/quadro-medalhas"
      element={
        <PrivateRoute redirectTo="/login">
          <QuadroMedalhas />
        </PrivateRoute>
      }
    />

    <Route
      path="/recordistas"
      element={
        <PrivateRoute redirectTo="/login">
          <Recordistas />
        </PrivateRoute>
      }
    />

    <Route path="*" element={<NotFound />} />
  </Routes>
);
