import { api } from './api';

export async function getAchievements(filters?: any[], limit = 100) {
  let payload: any[] = [];

  if (filters) {
    payload.concat(filters);
  }

  payload.push({
    $sort: {
      time: -1,
    },
  });

  if (limit) {
    payload.push({
      $limit: limit,
    });
  }

  const { data } = await api.post(`database/achievement/aggregate`, payload);
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

export async function getPointsHistory(player?: string, start?: any, end?: any) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };
    const { data } = await api.post('find/extrato_pontos', payload, { headers: { Range: 'items=0-1000' } });
    // console.log('getKPIHistorico', data);

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    // console.log('ERROR: getKPIHistorico ', e);
    throw e;
  }
}

export async function getPointsSummary(player?: string, start?: any, end?: any) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };
    const { data } = await api.post('find/extrato_resumo', payload, { headers: { Range: 'items=0-1000' } });
    // console.log('getKPIHistorico', data);

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data[0];
    }
  } catch (e) {
    // console.log('ERROR: getKPIHistorico ', e);
    throw e;
  }
}

export async function getReconhecimentos(player?: string, start?: any, end?: any) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };
    const { data } = await api.post('find/resultado_reconhecimento', payload, { headers: { Range: 'items=0-1000' } });
    // console.log('getKPIHistorico', data);

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    // console.log('ERROR: getKPIHistorico ', e);
    throw e;
  }
}

export async function getDestaqueCultura(player?: string, start?: any, end?: any) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };
    const { data } = await api.post('find/resultado_cultura', payload, { headers: { Range: 'items=0-1000' } });
    // console.log('getKPIHistorico', data);

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    // console.log('ERROR: getKPIHistorico ', e);
    throw e;
  }
}

export async function getMissaoGestor(player?: string, start?: any, end?: any) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };

    const { data } = await api.post('find/extrato_missao_gestor', payload, { headers: { Range: 'items=0-1000' } });

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    throw e;
  }
}

export async function getMissaoEquipe(player?: string, start?: any, end?: any) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };

    const { data } = await api.post('find/extrato_missao_equipe', payload, { headers: { Range: 'items=0-1000' } });

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    throw e;
  }
}

export async function updateAchievement(data?: any[]) {
  try {
    const response = await api.put('/database/achievement?strict=true', data);
    if (!response.data) {
      throw new Error('Erro ao salvar achievement');
    } else {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function getTeamStatus(unidade: string) {
  let payload: any[] = [];

  if (unidade) {
    payload.push(
      {$match:{ "player": unidade }},
      {$group:{ _id: "$item", total:{$sum: "$total"}}}
    );
  }

  const { data } = await api.post(`database/achievement/aggregate?strict=true`, payload);
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

export async function getNovasEntregas(userId?: string) {
  let payload: any[] = [];

  payload.push(
    {$match: {
      "player": userId,
      "type": 2,
      "time": { $gte: { $date: "2024-08-01T00:00:00.000Z"}},
      "extra.dataEntrega": { $exists: false },
      $expr: {
          $gte: [
              "$$NOW",
              { 
                "$add": [
                  "$time",
                  { "$multiply": [2, 24, 60, 60, 1000] }
                ]
              }
          ]
      }
    }},
    {
      $lookup: {
        from: "catalog_item",
        localField: "item",
        foreignField: "_id",
        as: "catalogItem",
      },
    },
    {
      $unwind: "$catalogItem",
    },
    {$match:{ "catalogItem.catalogId": "experiencias" }},
    {$group:{"_id": null, "total": {$sum: 1}}}
  );

  const { data } = await api.post(`database/achievement/aggregate?strict=true`, payload);
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

export async function getEntregas(userId?: string) {
  let payload: any[] = [];

  payload.push(
    {$match:{ "player": userId, "type": 2, "time": { $gte: { $date: "2024-08-01T00:00:00.000Z"}} }},
    {
      $lookup: {
        from: "catalog_item",
        localField: "item",
        foreignField: "_id",
        as: "catalogItem",
      },
    },
    {
      $unwind: "$catalogItem",
    },
    {$match:{ "catalogItem.catalogId": "experiencias" }},
    {
      "$addFields": {
        "extra.dataShowMessage": {
          "$add": [
            "$time",
            { "$multiply": [3, 24, 60, 60, 1000] }
          ]
        }
      }
    }
  );

  const { data } = await api.post(`database/achievement/aggregate?strict=true`, payload);
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}