import React, { useState, useEffect, ReactNode } from "react";
import { TourProvider } from "@reactour/tour";
import { useAuth } from "context/AuthContext";
import { updateUserProfile } from "services/profile.service";

interface TourStepsProps {
  children: ReactNode;
}

interface TourStep {
  selector: string;
  content: string;
}

const TourSteps: React.FC<TourStepsProps> = ({ children }) => {
  const { user } = useAuth();
  const [steps, setSteps] = useState<TourStep[]>([]);

  useEffect(() => {
    // Simulando a definição dos steps após carregar os dados
    const newSteps: TourStep[] = [
      {
        selector: '[data-tour="step-1"]',
        content: "Esta é a nova roleta, teste sua sorte =)",
      },
    ];

    // Atualiza os steps após um tempo ou carregamento de dados
    setSteps(newSteps);
  }, [user]);

  const handleClose = () => {
    // Adicione aqui a função que você deseja executar ao fechar o tour
    if (user) {
      user.extra.tourRoullet = true;

      updateUserProfile(user);
    }
  };

  if (steps.length === 0) {
    // Renderiza um loader ou outro componente enquanto os steps estão sendo definidos
    return <div>Loading...</div>;
  }

  return (
    <TourProvider
      steps={steps}
      onClickClose={({ setCurrentStep, currentStep, steps, setIsOpen }) => {
        if (steps) {
          if (steps && currentStep === steps.length - 1) {
            setIsOpen(false);
            handleClose();
          }
        }
      }}
      onClickMask={({ setCurrentStep, currentStep, steps, setIsOpen }) => {
        if (steps) {
          if (currentStep === steps.length - 1) {
            setIsOpen(false);
            handleClose();
          }
        }
      }}
      padding={{ 
        mask: 10, 
        popover: [5, 10], 
        wrapper: 0 
      }}
    >
      {children}
    </TourProvider>
  );
};

export default TourSteps;
