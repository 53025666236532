import React, { Component, ReactNode } from 'react';
import parse from 'html-react-parser';

let captcha_value = '';
let captcha_number = 6; // padrao 6
let backgroundColor_value = 'white';
let fontColor_value = 'black';
let charMap_value = '';
let LoadCanvasTemplate_HTML = '<div><canvas id="canv"></canvas></div>';
let LoadCanvasTemplateNoReload_HTML = '<div><canvas id="canv"></canvas></div>';
let padding_value = 0;
let scapeBot_value = false;

export const loadCaptchaEnginge = (
    numberOfCharacters: number,
    backgroundColor = 'white',
    fontColor = 'black',
    charMap = '',
    padding = 10,
    scapeBot = false
  ) => {
    backgroundColor_value = backgroundColor;
    fontColor_value = fontColor;
    charMap_value = charMap;
    captcha_number = numberOfCharacters;    
    padding_value = padding;
    scapeBot_value = scapeBot;
    let retVal = '';
    let charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  
    if (charMap === 'upper') {
      charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    } else if (charMap === 'lower') {
      charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
    } else if (charMap === 'numbers') {
      charset = '0123456789';
    } else if (charMap === 'special_char') {
      charset = '~`!@#$%^&*()_+-=[]{}\\|:\'<>?,./';
    }
  
    let length = numberOfCharacters;
  
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
  
    let captcha = retVal;
    captcha_value = captcha;
  
    let length_height_canvas = Math.round(length / 3);
  
    let canvas = document.getElementById('canv') as HTMLCanvasElement | null;
    if (!canvas) {
      return;
    }
    let ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }
    let text = captcha;
    let x = padding;
    let y = padding + 15;
    let lineheight = 30;
  
    let canvas_height = (length - length_height_canvas) * 20;
    let lines = text.split('\n');
    let lineLengthOrder = lines.slice(0).sort((a, b) => b.length - a.length);
    ctx.canvas.width = length * 25 + padding * 2;
    ctx.canvas.height = lines.length * lineheight + padding * 2;
  
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    
    if(scapeBot){
        // Adicionar ruído no fundo
        for (let i = 0; i < 150; i++) {
        ctx.fillStyle = getRandomColor();
        ctx.beginPath();
        ctx.arc(Math.random() * ctx.canvas.width, Math.random() * ctx.canvas.height, Math.random() * 3, 0, Math.PI * 2);
        ctx.fill();
        }
    
        // Adicionar linhas de ruído
        for (let i = 0; i < 5; i++) {
        ctx.strokeStyle = getRandomColor();
        ctx.lineWidth = 1 + Math.random() * 2;
        ctx.beginPath();
        ctx.moveTo(Math.random() * ctx.canvas.width, Math.random() * ctx.canvas.height);
        ctx.lineTo(Math.random() * ctx.canvas.width, Math.random() * ctx.canvas.height);
        ctx.stroke();
        }
    }
  
    ctx.textBaseline = 'middle';
    ctx.font = 'italic 20px Arial';
    ctx.fillStyle = fontColor;
  
    let num = 0;
    for (let i = 0; i < length; i++) {
      num = num + 1;
      let height_num = padding + 20 * num;
      ctx.save();
      ctx.translate(height_num, Math.round(Math.random() * (15 - 12) + 12) + padding);
      ctx.rotate((Math.random() - 0.5) * 0.4); // Rotacionar texto aleatoriamente
      ctx.fillText(retVal[i], 0, 0);
      ctx.restore();
    }
};


const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export const reloadCaptcha = () => {
    loadCaptchaEnginge(captcha_number, backgroundColor_value, fontColor_value, charMap_value, padding_value, scapeBot_value );
};

export const validateCaptcha = (userValue: string, reload = true): boolean => {
  if (userValue !== captcha_value) {
    if (reload) {
        loadCaptchaEnginge(captcha_number, backgroundColor_value, fontColor_value, charMap_value, padding_value, scapeBot_value );
    }
    return false;
  }
  return true;
};

interface LoadCanvasTemplateProps {
  reloadText?: ReactNode;
  reloadColor?: string;
}

export class LoadCanvasTemplate extends Component<LoadCanvasTemplateProps> {
  render() {
    const reload_text = this.props.reloadText || 'Reload Captcha';
    const reload_color = this.props.reloadColor || 'blue';

    LoadCanvasTemplate_HTML = `<div><canvas id="canv"></canvas></div>`;

    return (
      <div className='flex items-center justify-center'>
        {parse(LoadCanvasTemplate_HTML)}
        <button className="ml-2 text-[1.5em]" id="reload_href" style={{ cursor: 'pointer', color: reload_color }} onClick={reloadCaptcha} title='Reload Captcha'>
          {reload_text}
        </button>
      </div>
    );
  }
}

export class LoadCanvasTemplateNoReload extends Component {
  render() {
    return <>{parse(LoadCanvasTemplateNoReload_HTML)}</>;
  }
}
