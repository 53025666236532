import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";

import { useModal } from "context/ModalContext";

import Concluido from "assets/img/concluido.png";
import Conquistado from "assets/img/conquistado.png";
import Novo from "assets/img/novo.png";
import Apurado from "assets/img/apurado2.png";
import { Link, NavLink } from "react-router-dom";
import { Button, Modal } from "flowbite-react";
import { AiOutlineExclamationCircle } from "react-icons/ai";

import "./styles.scss";

export function DetailChallengeModal() {
  const { user } = useAuth();
  const { openModal, modalData, showModal, closeModal } = useModal();

  const [openModalFlow, setOpenModalFlow] = useState<string | undefined>();
  const props = { openModalFlow, setOpenModalFlow };

  const modalID = "modal-detail-challenge";

  const [modalIsOpen, setIsOpen] = useState(false);

  ReactModal.setAppElement("#modal");

  function handleCloseModal() {
    closeModal();
  }

  function afterOpenModal() {
    //
  }

  function handleShowAvatar() {
    //
  }

  useEffect(() => {
    if (!user) return;
    setIsOpen(openModal === modalID);
  }, [user, openModal, modalData]);

  if (!user) return null;

  if (!modalData || modalData?.length < 1) return null;

  const item = modalData.config;

  const Badge = () => {
    return (
      <>
        {!!item.completed && (
          <img src={Conquistado} alt="Conquistado" className="infoDesafio" />
        )}
        {(!!item.apurado || !!item.isApurado) && !item.completed && (
          <img src={Apurado} alt="Conquistado" className="infoDesafio" />
        )}
        {!item.vigente &&
        !item.completed &&
        !item.apurado &&
        !item.isApurado ? (
          <img src={Concluido} alt="Conquistado" className="infoDesafio" />
        ) : (
          <></>
        )}
        {item.vigente && item.novo && !item.completed ? (
          <img src={Novo} alt="Concluído" className="infoDesafio" />
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-blue-dark backdrop-blur-sm bg-opacity-90 flex flex-col items-center justify-center"
        contentElement={() => (
          <div id="modalDetailChallenge">
            <div className="bg-white flex flex-col items-center flex-none mx-5">
              <Button
                className="btnClose"
                type="button"
                onClick={() => handleCloseModal()}
              >
                X
              </Button>

              <div className="text-center">
                {item.badge && item.badge.original.url && (
                  <div>
                    <Badge />
                    <img
                      src={item.badge.original.url}
                      alt=""
                      className="imageDetail"
                    />
                  </div>
                )}
                {!!item.regulamento && (
                  <a
                    href={item.regulamento}
                    className="border border-solid border-indigo-500 text-[#0a3cab] p-2 inline-block my-2.5 uppercase no-underline font-bold hover:no-underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Regulamento
                  </a>
                )}

                {!!item.apurado && !item.isApurado && (
                  <Link
                    to={`/desafios/apuracao?item=${item._id}`}
                    className="border border-solid border-indigo-500 text-[#0a3cab] p-2 inline-block my-2.5 mx-3 uppercase font-bold no-underline hover:no-underline"
                  >
                    Resultados
                  </Link>
                )}

                {!item.apurado && !!item.isApurado && (
                  <button
                    className="border border-solid border-indigo-500 text-[#0a3cab] p-2 inline-block my-2.5 mx-3 uppercase font-bold"
                    onClick={() => {
                      props.setOpenModalFlow("pop-up");
                    }}
                  >
                    Resultados
                  </button>
                )}
              </div>
            </div>
            <Modal
              show={props.openModalFlow === "pop-up"}
              size="md"
              popup
              onClose={() => props.setOpenModalFlow(undefined)}
            >
              <Modal.Header />
              <Modal.Body>
                <div className="text-center">
                  <AiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                  <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                    O desafio foi apurado, mas não existem ganhadores.
                  </h3>
                  <div className="flex justify-center gap-4">
                    <Button onClick={() => props.setOpenModalFlow(undefined)}>
                      OK
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        )}
      />
    </>
  );
}
