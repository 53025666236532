import { api } from "./api";
import { fetchUser } from "./auth.service";

export async function uploadPicture(payload: any) {
  try {
    var form_data = new FormData();
    form_data.append("file", new File([DataURIToBlob(payload)], "profile.jpg"));
    form_data.append(
      "extra",
      JSON.stringify({
        session: "avatar",
        thumbnails: [
          { name: "small", width: 160, height: 160 },
          { name: "medium", width: 260, height: 260 },
        ],
      })
    );

    const { data } = await api.post("upload/file", form_data);
    return data.status === "OK" ? data.uploads[0] : data;
  } catch (err) {
    console.log("uploadPicture", err);
  }
}

export async function updateUserProfile(payload: any) {
  // let player = await fetchUser()
  // player = { ...player, ...payload }
  const { data } = await api.put("player/me", payload);
  return data;
}

export async function updateUserImage(player: string, payload: any) {
  // let player = await fetchUser()
  // player = { ...player, ...payload }
  const { data } = await api.post(`player/${player}/image`, payload);
  return data;
}

export async function updateUserStatus() {
  // const { data } = await api.put('player/me/status')
  await api.put("player/me/status", {});
  let player = await fetchUser();
  return player;
}

export async function getLevel() {
  const { data } = await api.get("level");
  return data;
}

export async function deleteMyAccount(player: string) {
  const { data } = await api.delete(`/database/player?q=_id:'${player}'`);
  return data;
}

function DataURIToBlob(dataURI: string) {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

export function fetchDataURL(url: string) {
  return new Promise<string>((resolve, reject) => {
    try {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          resolve(`${reader.result}`);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    } catch (e) {
      reject(e);
    }
  });
}

export function resizeImage(base64Str: string) {
  return new Promise<string>((resolve, reject) => {
    // console.log('resizeImage', base64Str);
    var img = new Image();
    img.src = base64Str;

    img.onload = () => {
      var canvas = document.createElement("canvas");
      var MAX_WIDTH = 256;
      var MAX_HEIGHT = 256;
      var width = img.width;
      var height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      if (ctx) {
        const x = -(width / 2);
        const y = -(height / 4);
        ctx.drawImage(img, x, y, width * 2, height * 2);
      }

      resolve(canvas.toDataURL());
    };
  });
}

export async function findPlayer(search?: string, all = false) {
  try {
    const payload = {
      search,
      all,
    };
    const { data } = await api.post("find/players", payload);
    return data;
  } catch (err) {
    throw err;
  }
}

export async function getUserProfile(_id: string) {
  try {
    const { data } = await api.get(`player/${_id}/status`);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getUserFriends(_id: string) {
  try {
    const resFriends = await api.get(`player/${_id}/friend`);

    const payload = [
      {
        $match: {
          _id: {
            $in: [...resFriends.data],
          },
        },
      },
      {
        $project: {
          _id: 1,
          name: 1,
          team: 1,
          friends: 1,
          extra: 1,
        },
      },
    ];

    const { data } = await api.post(
      `database/player_status/aggregate`,
      payload
    );

    // console.log(data);

    return data;
  } catch (e) {
    throw e;
  }
}

export async function addFriend(_id: string, friendId: string) {
  try {
    await api.get(`player/${_id}/friend/${friendId}`);
    await api.get(`player/${friendId}/friend/${_id}`);
    return;
  } catch (e) {
    throw e;
  }
}

export async function getNomeUnidade(id: string) {
  const payload = [
    {
      $match: {
        _id: id,
      },
    },
  ];
  const { data } = await api.post("database/brb_unidade__c/aggregate", payload);

  return data;
}

export async function getPointsUnidade(unidade?: string) {
  try {
    const payload = [
      { $match: { player: unidade, type: 0, total: { $gt: 0 } } },
      {
        $group: {
          _id: "$player",
          total: { $sum: "$total" },
        },
      },
    ];
    const { data } = await api.post("database/achievement/aggregate", payload);
    return data;
  } catch (err) {
    throw err;
  }
}
