/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth } from "context/AuthContext";
import { Outlet } from "react-router";
import { UserInfo } from "components/user-info";

import "./styles.scss";
import { NavMenu } from "components/navmenu";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useModal } from "context/ModalContext";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  IPrizes,
  getPrizes,
  getVirtualGoods,
  purchaseItem,
} from "services/store.service";
import { getTeamStatus } from "services/achievement.service";
import { getTeamById } from "services/teams.service";
import { DetailCardsModal } from "./Components/modal-detail";
import { toast } from "react-toastify";
import { RegulamentoFooter } from "components/regulamento-footer";
import { PurchaseItemModal } from "./Components/modal-purchase";

import IconCard from "assets/img/icon-card-sm.png";

export function Premios() {
  const { user } = useAuth();
  const { showModal } = useModal();
  const [cards, setCards] = useState<any>();
  const [teamOwner, setTeamOwner] = useState<any>();
  const [teamStatus, setTeamStatus] = useState<any>();
  const [prizes, setPrizes] = useState<IPrizes[]>();
  const [prizesTeam, setPrizesTeam] = useState<IPrizes[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const responsivePremios = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  async function loadTeamStatus() {
    const data = await getTeamStatus(user?.extra.unidade);
    if (data.length) {
      let localTeamStatus: { [key: string]: any } = {};
      data.forEach(function (o, i) {
        localTeamStatus[o._id] = o?.total;
      });
      setTeamStatus(localTeamStatus);
    }
    loadItems();
  }

  async function loadItems() {
    const data = await getVirtualGoods();
    setCards(data);
    setLoading(false);
  }

  async function loadTeam() {
    const data = await getTeamById(user?.extra.unidade);
    setTeamOwner(data.owner);
  }

  async function loadPrizes() {
    const data = (await getPrizes(user ? user._id : '', user?.extra.unidade)) as IPrizes[];
    setPrizes(data.filter((e) => e.player === user?._id));
    setPrizesTeam(data.filter((e) => e.player === user?.extra.unidade));
  }

  useEffect(() => {
    setLoading(true);
    if (user?._id) {
      loadTeam();
      loadTeamStatus();
      loadPrizes();
    }
  }, [user]);

  function getItem(id: string) {
    let obj = { image: { medium: { url: "" } } };
    cards?.forEach(function (o: any) {
      if (o._id === id) {
        obj = o;
      }
    });
    return obj;
  }

  function isRequirementUnlocked(o: any) {
    let ok = false;
    if (
      o.type === 0 &&
      user?.point_categories[o.item] &&
      user?.point_categories[o.item] >= o?.total
    ) {
      ok = true;
    } else if (
      o.type === 1 &&
      user?.challenges[o.item] &&
      user?.challenges[o.item] >= o?.total
    ) {
      ok = true;
    } else if (
      o.type === 2 &&
      user?.catalog_items[o.item] &&
      user?.catalog_items[o.item] >= o?.total
    ) {
      ok = true;
    } else if (o.type >= 3) {
      //ignorar qualquer outro tipo como level, crowning etc.
      ok = true;
    }
    return ok;
  }

  function isTeamRequirementUnlocked(o: any) {
    var ok = false;
    if (o.type === 0 && teamStatus[o.item] && teamStatus[o.item] >= o?.total) {
      ok = true;
    } else if (
      o.type === 1 &&
      teamStatus[o.item] &&
      teamStatus[o.item] >= o?.total
    ) {
      ok = true;
    } else if (
      o.type === 2 &&
      teamStatus[o.item] &&
      teamStatus[o.item] >= o?.total
    ) {
      ok = true;
    } else if (o.type >= 3) {
      //ignorar qualquer outro tipo como level, crowning etc.
      ok = true;
    }
    return ok;
  }

  function totRequirement(item: any) {
    let ok = 0;
    if (item && item.requires) {
      item.requires.forEach(function (o: any) {
        if (o.type === 0 && user?.point_categories[o.item]) {
          ok = ok + user?.point_categories[o.item];
        } else if (o.type === 1 && user?.challenges[o.item]) {
          ok = ok + user?.challenges[o.item];
        } else if (o.type === 2 && user?.catalog_items[o.item]) {
          ok = ok + user?.catalog_items[o.item];
        }
      });
    }
    return ok;
  }

  function totTeamRequirement(item: any) {
    var ok = 0;
    if (item && item.requires) {
      item.requires.forEach(function (o: any) {
        if (o.type === 0 && teamStatus && teamStatus[o.item]) {
          ok = ok + teamStatus[o.item];
        } else if (o.type === 1 && teamStatus && teamStatus[o.item]) {
          ok = ok + teamStatus[o.item];
        } else if (o.type === 2 && teamStatus && teamStatus[o.item]) {
          ok = ok + teamStatus[o.item];
        }
      });
    }
    return ok;
  }

  function totRequirementOk(item: any) {
    let ok = 0;
    if (item && item.requires) {
      item.requires.forEach(function (o: any) {
        if (
          o.type === 0 &&
          user?.point_categories[o.item] &&
          user?.point_categories[o.item] >= o?.total
        ) {
          ok = ok + 1;
        } else if (
          o.type === 1 &&
          user?.challenges[o.item] &&
          user?.challenges[o.item] >= o?.total
        ) {
          ok = ok + 1;
        } else if (
          o.type === 2 &&
          user?.catalog_items[o.item] &&
          user?.catalog_items[o.item] >= o?.total
        ) {
          ok = ok + 1;
        } else if (o.type >= 3) {
          //ignorar qualquer outro tipo como level, crowning etc.
          ok = ok + 1;
        }
      });
    }
    return ok;
  }

  function totTeamRequirementOk(item: any) {
    var ok = 0;
    if (item && item.requires) {
      item.requires.forEach(function (o: any) {
        if (
          o.type === 0 &&
          teamStatus[o.item] &&
          teamStatus[o.item] >= o?.total
        ) {
          ok = ok + 1;
        } else if (
          o.type === 1 &&
          teamStatus[o.item] &&
          teamStatus[o.item] >= o?.total
        ) {
          ok = ok + 1;
        } else if (
          o.type === 2 &&
          teamStatus[o.item] &&
          teamStatus[o.item] >= o?.total
        ) {
          ok = ok + 1;
        } else if (o.type >= 3) {
          //ignorar qualquer outro tipo como level, crowning etc.
          ok = ok + 1;
        }
      });
    }
    return ok;
  }

  function hasCardColecionavel() {
    let has = false;
    cards?.forEach(function (o: any) {
      if (o.catalogId === "premios" && totRequirement(o) > 0) {
        has = true;
      }
    });
    return has;
  }

  function hasCardColecionavelUnidade() {
    var has = false;
    cards?.forEach(function (o: any) {
      if (o.catalogId === "premios" && totTeamRequirement(o) > 0) {
        has = true;
      }
    });
    return has;
  }

  function showPremioCards(item: any, type: string) {
    let cards: any[] = [];

    if (item && item.requires && item.catalogId === "premios") {
      item.requires.forEach(function (o: any) {
        let card = {
          imgPremio: "",
          my: null,
          imgCard: { image: { medium: { url: "" } } },
          type: "",
        };
        card.imgPremio = item.image.medium.url;
        card.imgCard = getItem(o.item);
        card.my =
          type === "funcionario"
            ? user?.catalog_items[o.item]
            : teamStatus[o.item];
        card.type = type;
        cards.push(card);
      });
    }
    showModal("modal-detail-cards", { config: cards });
  }

  function purchase(item: any, type: string) {
    if (!!user?._id) {
      const idPurchase = type === "unidade" ? user.extra.unidade : user._id;

      purchaseItem(item._id, idPurchase).then(function (data) {
        if (data.status === "OK") {
          showModal("modal-purchase-item", item);

          //refreshUser();
        } else if (data.status === "UNAUTHORIZED") {
          toast(`❌ Ocorreu um erro, tente novamente mais tarde.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast(`❌ Ocorreu um erro, tente novamente mais tarde.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    }
  }

  function hasUnlockedPremio() {
    var has = false;
    cards?.forEach(function (o: any) {
      if (o.catalogId === "premios" && isItemLocked(o) === false) {
        has = true;
      }
    });
    return has;
  }

  function hasTeamUnlockedPremio() {
    var has = false;
    cards?.forEach(function (o: any) {
      if (o.catalogId === "premios" && isTeamItemLocked(o) === false) {
        has = true;
      }
    });
    return has;
  }

  function isItemLocked(item: any) {
    var ok = 0;
    if (item && item.requires) {
      item.requires.forEach(function (o: any) {
        if (
          o.type === 0 &&
          user?.point_categories[o.item] &&
          user?.point_categories[o.item] >= o?.total
        ) {
          ok = ok + 1;
        } else if (
          o.type === 1 &&
          user?.challenges[o.item] &&
          user?.challenges[o.item] >= o?.total
        ) {
          ok = ok + 1;
        } else if (
          o.type === 2 &&
          user?.catalog_items[o.item] &&
          user?.catalog_items[o.item] >= o?.total
        ) {
          ok = ok + 1;
        } else if (o.type >= 3) {
          //ignorar qualquer outro tipo como level, crowning etc.
          ok = ok + 1;
        }
      });
    }
    return !(ok === item.requires.length);
  }

  function isTeamItemLocked(item: any) {
    var ok = 0;
    if (item && item.requires) {
      item.requires.forEach(function (o: any) {
        if (
          o.type === 0 &&
          teamStatus &&
          teamStatus[o.item] &&
          teamStatus[o.item] >= o?.total
        ) {
          ok = ok + 1;
        } else if (
          o.type === 1 &&
          teamStatus &&
          teamStatus[o.item] &&
          teamStatus[o.item] >= o?.total
        ) {
          ok = ok + 1;
        } else if (
          o.type === 2 &&
          teamStatus &&
          teamStatus[o.item] &&
          teamStatus[o.item] >= o?.total
        ) {
          ok = ok + 1;
        } else if (o.type >= 3) {
          //ignorar qualquer outro tipo como level, crowning etc.
          ok = ok + 1;
        }
      });
    }
    return !(ok === item.requires.length);
  }

  function isTeamManager() {
    return user?._id === teamOwner;
  }

  function returnTextReward(item: any){
    if (!item.rewards || !item.rewards[0] || !item.rewards[0].item) {
      return "Recompensa não definida.";
    }

    switch (item.rewards[0].item) {
      case "star":
        return "para trocar no <strong>Mercado de Estrelas</strong>."
        break;
      case "brb_points":
        return "para trocar no <strong>Mundo BRB</strong>."
        break;
      case "giro":
        return "Para utilizar na <strong>Roleta</strong>."
        break;
      default:
        return "";
    }
  }

  if (!user) return null;

  return (
    <>
      <Helmet>
        <title>VAI PRA CIMA, BRB!</title>
      </Helmet>
      <div id="home-wrapper" className="min-h-screen">
        <div
          className="z-10 relative flex flex-col items-center space-y-0 md:space-y-10 md:p-10"
          id="pagePremios"
        >
          <NavMenu />

          <div className="w-full flex justify-center p-2 md:p-0">
            <UserInfo />
          </div>
          <div className="bg-[#0938A3] w-screen text-white md:-mx-10 bgShadow">
            <div className="w-full md:w-[1200px] mx-auto  p-5 md:p-8 meusPremios">
              <h2>
                Confira seus <strong>prêmios</strong>
              </h2>

              {!hasUnlockedPremio() && prizes?.length === 0 && (
                <p className="text-center noCards">
                  Você não possui prêmios para trocar por pontos no{" "}
                  <strong>mundo BRB</strong>
                </p>
              )}

              <div className="">
                {cards && (
                  <Carousel
                    responsive={responsivePremios}
                    className="contentCards"
                  >
                    {cards
                      ?.filter((e: any) => {
                        if (
                          e.catalogId === "premios" &&
                          totRequirement(e) >= 1 &&
                          !isItemLocked(e)
                        ) {
                          return e;
                        }
                        return false;
                      })
                      .map((item: any, i: number) => (
                        <span className="containerCard" key={i}>
                          <div className="item">
                            <img
                              src={item.image.medium.url}
                              alt=""
                              title={item.name}
                            />
                            <h3>
                              <strong>Parabéns</strong>
                            </h3>
                            <div>
                              {/* <img
                                src={Presente}
                                alt=""
                                className="iconPresente"
                              /> */}
                              {item.rewards && item.rewards[0] ? (
                                <p>
                                  Você acabou de ganhar <br />
                                  <strong>
                                    {item.rewards[0]?.total} pontos
                                  </strong>{" "}
                                  {/* para trocar no <strong>Mundo BRB</strong> */}
                                  <div
                                    dangerouslySetInnerHTML={{ __html: returnTextReward(item) }}
                                  ></div>
                                </p>
                              ) : (
                                <p>
                                  Você acabou de ganhar <br />
                                  <strong>{item.name}</strong>
                                </p>
                              )}
                            </div>
                          </div>
                          {item.rewards && item.rewards[0] ? (
                            <button
                              onClick={() => {
                                purchase(item, "funcionario");
                              }}
                            >
                              RECEBER PONTOS
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                purchase(item, "funcionario");
                              }}
                            >
                              RECEBER
                            </button>
                          )}
                        </span>
                      ))}
                    {prizes?.map((prize) => (
                      <span className="containerCard" key={prize._id}>
                        <div className="item">
                          <img src={prize.image} alt="" title={prize.name} />

                          <h3>
                            <strong>Parabéns</strong>
                          </h3>
                          <div>
                            <p>
                              Você ganhou <br />
                              <strong>{prize.name}</strong>
                            </p>
                          </div>
                        </div>
                        <button
                          onClick={() => {
                            showModal("modal-purchase-item", prize);
                          }}
                        >
                          INFORMAÇÕES
                        </button>
                      </span>
                    ))}
                  </Carousel>
                )}
              </div>
            </div>

            <div className="w-full md:w-[1200px] mx-auto  p-5 md:p-8 meusPremios">
              <h2>
                Prêmios da <strong>unidade</strong>
              </h2>

              {!hasTeamUnlockedPremio() && prizesTeam?.length === 0 && (
                <p className="text-center noCards">
                  A unidade não possui prêmios para trocar por pontos no{" "}
                  <strong>mundo BRB</strong>
                </p>
              )}

              <div className="">
                {cards && (
                  <Carousel
                    responsive={responsivePremios}
                    className="contentCards"
                  >
                    {cards
                      ?.filter((item: any) => {
                        if (
                          item.catalogId === "premios" &&
                          totTeamRequirement(item) >= 1 &&
                          !isTeamItemLocked(item)
                        ) {
                          return item;
                        }
                        return false;
                      })
                      .map((item: any, i: number) => (
                        <span className="containerCard" key={i}>
                          <div className="item">
                            <img
                              src={item.image.medium.url}
                              alt=""
                              title={item.name}
                            />
                            <h3>
                              <strong>Parabéns</strong>
                            </h3>
                            <div>
                              {/* <img
                                src={Presente}
                                alt=""
                                className="iconPresente"
                              /> */}
                              {item.rewards && item.rewards[0] ? (
                                <p>
                                  Sua unidade acabou de ganhar <br />
                                  <strong>
                                    {item.rewards[0]?.total} pontos
                                  </strong>{" "}
                                  {/* para trocar no <strong>Mundo BRB</strong> */}
                                  <div
                                    dangerouslySetInnerHTML={{ __html: returnTextReward(item) }}
                                  ></div>
                                </p>
                              ) : (
                                <p>
                                  Sua unidade acabou de ganhar <br />
                                  <strong>{item.name}</strong>
                                </p>
                              )}
                            </div>
                          </div>
                          {!isTeamItemLocked(item) && !isTeamManager() && (
                            <button>
                              Avise o responsável pela unidade para regatar
                            </button>
                          )}
                          {!isTeamItemLocked(item) && isTeamManager() && (
                            <>
                              {item.rewards && item.rewards[0] ? (
                                <button
                                  onClick={() => {
                                    purchase(item, "unidade");
                                  }}
                                >
                                  RECEBER PONTOS
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    purchase(item, "unidade");
                                  }}
                                >
                                  RECEBER
                                </button>
                              )}
                            </>
                          )}
                        </span>
                      ))}
                    {prizesTeam?.map((prize) => (
                      <span className="containerCard" key={prize._id}>
                        <div className="item">
                          <img src={prize.image} alt="" title={prize.name} />
                          <h3>
                            <strong>Parabéns</strong>
                          </h3>

                          <div>
                            {" "}
                            <p>
                              Sua unidade ganhou <br />
                              <strong>{prize.name}</strong>
                            </p>
                          </div>
                        </div>
                        <button
                          onClick={() => {
                            showModal("modal-purchase-item", {
                              ...prize,
                              type: "unidade",
                              isOwner: isTeamManager(),
                            });
                          }}
                        >
                          INFORMAÇÕES
                        </button>
                      </span>
                    ))}
                  </Carousel>
                )}
              </div>
            </div>
          </div>

          <div className=" w-screen text-white md:-mx-10 ">
            <div className="w-full md:w-[1200px] mx-auto p-5 md:p-8 meusCards">
              <h2>
                Meus <strong>cards</strong>
              </h2>

              <div className="">
                {!hasCardColecionavel() && (
                  <p className="text-center noCards">Você não possui cards</p>
                )}
                {cards && hasCardColecionavel() && (
                  <Carousel
                    responsive={responsivePremios}
                    className="contentCards"
                  >
                    {cards
                      ?.filter((e: any) => {
                        if (
                          e.catalogId === "premios" &&
                          totRequirement(e) >= 1
                        ) {
                          return e;
                        }
                        return false;
                      })
                      .map((item: any, i: number) => (
                        <span key={i}>
                          <div className="item" title={item.name}>
                            <p className="title">{item.name}</p>
                            <p className="totalItems">
                              <span className="first">
                                {totRequirementOk(item)}
                              </span>{" "}
                              <span className="middle">/</span>{" "}
                              <span className="last">
                                {item.requires.length}
                              </span>
                            </p>
                            <img
                              src={item.image.medium.url}
                              alt=""
                              className="bgItem"
                            />
                            {item.requires.map((obj: any, i: number) => (
                              <span key={i}>
                                {isRequirementUnlocked(obj) && (
                                  <img
                                    src={getItem(obj.item).image.medium.url}
                                    alt=""
                                  />
                                )}
                              </span>
                            ))}
                            <div>
                              <p>
                                <img src={IconCard} alt="" />
                                Você possui {totRequirement(item)} card
                                {totRequirement(item) > 1 ? "s" : ""}
                              </p>
                              <p>
                                <button
                                  onClick={() => {
                                    showPremioCards(item, "funcionario");
                                  }}
                                >
                                  {" "}
                                  VER -&gt;
                                </button>
                              </p>
                            </div>
                          </div>
                        </span>
                      ))}
                  </Carousel>
                )}
              </div>
            </div>
            <div className="w-full md:w-[1200px] mx-auto p-5 md:p-8 meusCards">
              <h2>
                Cards da <strong>unidade</strong>
              </h2>

              <div className="">
                {!hasCardColecionavelUnidade() && (
                  <p className="text-center noCards">
                    Sua unidade não possui cards
                  </p>
                )}
                {cards && hasCardColecionavelUnidade() && (
                  <Carousel
                    responsive={responsivePremios}
                    className="contentCards"
                  >
                    {cards
                      ?.filter((e: any) => {
                        if (
                          e.catalogId === "premios" &&
                          totTeamRequirement(e) >= 1
                        ) {
                          return e;
                        }
                        return false;
                      })
                      .map((item: any, i: number) => (
                        <span key={i}>
                          <div className="item" title={item.name}>
                            <p className="title">{item.name}</p>
                            <p className="totalItems">
                              <span className="first">
                                {totTeamRequirementOk(item)}
                              </span>{" "}
                              <span className="middle">/</span>{" "}
                              <span className="last">
                                {item.requires.length}
                              </span>
                            </p>
                            <img
                              src={item.image.medium.url}
                              alt=""
                              className="bgItem"
                            />
                            {item.requires.map((obj: any, i: number) => (
                              <span key={i}>
                                {isTeamRequirementUnlocked(obj) && (
                                  <img
                                    src={getItem(obj.item).image.medium.url}
                                    alt=""
                                  />
                                )}
                              </span>
                            ))}
                            <div>
                              <p>
                                <img src={IconCard} alt="" />
                                Sua unidade possui {totTeamRequirement(
                                  item
                                )}{" "}
                                card
                                {totTeamRequirement(item) > 1 ? "s" : ""}
                              </p>
                              <p>
                                <button
                                  onClick={() => {
                                    showPremioCards(item, "unidade");
                                  }}
                                >
                                  {" "}
                                  VER -&gt;
                                </button>
                              </p>
                            </div>
                          </div>
                        </span>
                      ))}
                  </Carousel>
                )}
              </div>
            </div>
          </div>
        </div>
        <RegulamentoFooter />
      </div>
      <Outlet />
      <DetailCardsModal />
      <PurchaseItemModal />
    </>
  );
}
