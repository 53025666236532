/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "context/AuthContext";

import * as yup from "yup";

import Logo from "assets/img/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { centeredContent, whiteBox } from "styles";

import { Alert, Button, Checkbox, Label, TextInput } from "flowbite-react";
import { RegisterCredentials } from "models";
import { BiEnvelope, BiError, BiPhone } from "react-icons/bi";
import { useYupValidationResolver } from "services/utils";

import "./styles.scss";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { maskPhone } from "utils/mask";
import Tippy from "@tippyjs/react";

interface IFormInput extends RegisterCredentials {
  username: string;
  password: string;
  password_confirm: string;
  first_name: string;
  last_name: string;
  code: string;
  phone: string;
  email: string;
  accept: boolean;
}

export function Register() {
  const navigate = useNavigate();

  const [t] = useTranslation();

  let validateSchema = yup.object().shape({
    username: yup.string().required(`${t("username.required")}`),
    code: yup.string().required(`${t("code.required")}`),
  });

  let registerSchema = yup.object().shape({
    username: yup.string().required(`${t("username.required")}`),
    code: yup.string().required(`${t("code.required")}`),
    first_name: yup.string().required(`${t("first-name.required")}`),
    // last_name: yup.string().required(`${t('last-name.required')}`),
    phone: yup.string().required(`${t("phone.required")}`),
    email: yup.string().required(`${t("email.required")}`),
    password: yup
      .string()
      .min(8, `${t("password.short")}`)
      .matches(/^[^_.]/, 'Senha não pode começar com "_" ou "."')
      .matches(/[A-Z]/, "A senha deve conter pelo menos uma letra maiúscula")
      .matches(/[a-z]/, "A senha deve conter pelo menos uma letra minúscula")
      .matches(
        /[!@$%().?:{}<>]/,
        "A senha deve conter pelo menos um caractere especial"
      )
      .matches(/[0-9]/, "A senha deve conter pelo menos um número")
      .test(
        "no-invalid-chars",
        "A senha não pode conter caracteres inválidos, verifique os caracteres na ajuda",
        (value) => {
          if (!value) return true;
          const invalidChars = /[*;:_`'"&^#,|-]/;
          return !invalidChars.test(value);
        }
      )
      .required(`${t("password.required")}`),
    password_confirm: yup
      .string()
      .required(`${t("password.conirmation.required")}`)
      .oneOf([yup.ref("password")], `${t("password.match")}`),
    accept: yup.boolean().required(`${t("accept.required")}`),
  });

  const [validationSchema, setValidationSchema] = useState(validateSchema);

  const { register, setValue, handleSubmit } = useForm<IFormInput>({
    resolver: useYupValidationResolver(validationSchema),
    reValidateMode: "onChange",
  });

  const [submitLabel, setSubmitLabel] = useState("Continuar");
  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [validUser, setValidUser] = useState(false);
  const { onRegisterUser, onValidateUser } = useContext(AuthContext);

  async function onSubmit(data: IFormInput) {
    try {
      setLoading(true);
      setErrorMessage("");
      if (!validUser) {
        const result: any = await onValidateUser({
          _id: data.username,
          matricula: data.code,
        });
        try {
          if (result) {
            // setValue('email',result.email);
            setValue("first_name", result.name);
          }
        } catch (e) {}
        setValidUser(!!result);
        setLoading(false);
      } else {
        await onRegisterUser(data);
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMessage(error.message);
    }
  }

  function onSubmitError(error: any) {
    Object.keys(error).forEach((key) => {
      const errors = [];
      error[key].message && errors.push(error[key].message);
      setErrorMessage(errors.join(", "));
    });
  }

  useEffect(() => {
    setSubmitLabel(validUser ? "Cadastrar" : "Continuar");
    setValidationSchema(validUser ? registerSchema : validateSchema);
  }, [validUser]);

  function dica() {
    return (
      <>
        <p className="font-bold mb-1">Dicas de senha</p>
        <ul className="list-disc p-5 ">
          <li>Deve ter pelo menos 8 caracteres </li>
          <li>A senha não pode começar com . ou _ </li>
          <li>Deve ter pelo menos uma letra maiúscula</li>
          <li>Deve ter pelo menos uma letra minúscula</li>
          <li>Deve ter pelo menos um caracter especial</li>
          <li>Não são permitidos os caracteres (* ; : _ ` ' " & ^ # , | -)</li>
          <li>Deve ter pelo menos um número </li>
        </ul>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Cadastro - VAI PRA CIMA, BRB!</title>
      </Helmet>
      <div
        id="register-wrapper"
        className="min-h-screen flex flex-col md:flex-row 2xl:items-center md:justify-center"
      >
        {/* <div className="m-3 bg-white p-3 rounded-xl md:m-0 md:bg-none md:rounded-none md:flex-1 flex items-center justify-center py-4 md:p-0 md:max-h-screen md:fixed md:top-0 md:left-[23vw] md:bottom-0">
          <img src={Logo} alt="Logo" className="h-24 md:h-[50vh]" />
        </div> */}

        <div className={whiteBox}>
          <h1 className="font-bold text-2xl 2xl:text-4xl text-blue-dark leading-tight">
            Falta pouco <br className="hidden 2xl:block" />
            para começar a construir o futuro juntos
          </h1>

          <form
            className="w-full mt-2 space-y-2"
            onSubmit={handleSubmit(onSubmit, onSubmitError)}
          >
            <p className="py-3">
              Informe seu CPF e o código de validação para realizar o seu cadastro.
            </p>
            <div className="space-y-3">
              <div className="relative">
                <Label htmlFor="username">CPF</Label>
                <TextInput
                  {...register("username", {
                    onChange: (e) =>
                      (e.target.value = e.target.value.replace(/\D+/g, "")),
                  })}
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="false"
                  placeholder="Digite seu CPF"
                  maxLength={14}
                  required
                  onKeyUp={() => {
                    setErrorMessage("");
                  }}
                  disabled={validUser}
                />
              </div>

              <div className="relative">
                <Label htmlFor="code">
                  Código de Validação <small>
                    <br />(Dia e mês de nascimento seguido do dia e mês de admissão. Ex.: 08061707)
                  </small>
                </Label>
                <TextInput
                  {...register("code", {
                    onChange: (e) =>
                      (e.target.value = e.target.value.replace(/\D+/g, "")),
                  })}
                  id="code"
                  name="code"
                  type="text"
                  autoComplete="false"
                  placeholder="Digite o código de validação"
                  required
                  disabled={validUser}
                />
              </div>

              {validUser ? (
                <>
                  <div className="flex space-x-1">
                    <div className="relative flex-1">
                      <Label htmlFor="first_name">Nome</Label>
                      <TextInput
                        {...register("first_name")}
                        id="first_name"
                        name="first_name"
                        type="text"
                        autoComplete="false"
                        placeholder="Seu nome"
                        required
                        readOnly
                        disabled
                      />
                    </div>
                    {/* <div className="relative">
                      <Label htmlFor="last_name">Sobrenome</Label>
                      <TextInput
                        {...register('last_name')}
                        id="last_name"
                        name="last_name"
                        type="text"
                        autoComplete="false"
                        placeholder="Sobrenome"
                        required
                      />
                    </div> */}
                  </div>

                  <div>
                    <Label htmlFor="email">E-mail</Label>
                    <TextInput
                      {...register("email")}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="false"
                      placeholder="Digite seu e-mail BRB"
                      required
                    />
                  </div>

                  <div>
                    <Label htmlFor="phone">Telefone</Label>
                    <TextInput
                      {...register("phone", {
                        onChange: (e) =>
                          (e.target.value = maskPhone(e.target.value)),
                      })}
                      id="phone"
                      name="phone"
                      type="text"
                      autoComplete="false"
                      placeholder="Informe seu telefone"
                      required
                    />
                  </div>

                  {/* {showPassword && ( */}

                  <div>
                    <Label htmlFor="password">
                      Senha
                      <Tippy
                        arrow={false}
                        className="hidden lg:block bg-blue-light drop-shadow-lg p-4"
                        allowHTML={true}
                        touch={"hold"}
                        content={dica()}
                      >
                        <span className="ml-2 bg-[#0fbbf1] text-white rounded-full px-1">
                          ?
                        </span>
                      </Tippy>
                    </Label>
                    <TextInput
                      {...register("password")}
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="false"
                      placeholder="Digite sua senha"
                      required
                    />
                  </div>

                  <div>
                    <Label htmlFor="password_confirm">
                      Confirmação de Senha
                    </Label>
                    <TextInput
                      {...register("password_confirm")}
                      id="password_confirm"
                      name="password_confirm"
                      type="password"
                      autoComplete="false"
                      placeholder="Digite novamente a senha"
                      required
                    />
                  </div>

                  <div>
                    <Checkbox
                      {...register("accept")}
                      id="accept"
                      name="accept"
                      required
                    />{" "}
                    <Label htmlFor="accept">
                      Eu concordo com a{" "}
                      <a
                        className="text-blue-500"
                        href="https://novo.brb.com.br/politica-de-privacidade/"
                        target={"_blank"}
                        rel="noopener noreferrer"
                      >
                        política de privacidade
                      </a>
                      .
                    </Label>
                  </div>
                </>
              ) : null}

              {/* )} */}
            </div>

            {!!errorMessage && (
              <Alert icon={BiError} color={"failure"} className="pr-2">
                {errorMessage}
              </Alert>
            )}

            <div className="flex flex-row justify-center items-center space-x-1 pt-2">
              <Button
                type="submit"
                disabled={loading}
                className="w-full bg-blue-light"
              >
                {loading ? "Aguarde..." : submitLabel}
              </Button>
            </div>

            {/* <div className="flex flex-row justify-center items-center space-x-1 pt-2">
            <Button type="submit" disabled={loading} className="w-full bg-blue-light bg-opacity-20 color-blue-dark">
              Entrar em contato
            </Button>
          </div> */}

            <div className="text-center pt-2 space-y-3">
              <p>Duvidas sobre o acesso?</p>
              <div className="flex w-full space-x-3 justify-center">
                <button
                  onClick={() => navigate("/contato")}
                  type="button"
                  className="flex w-1/2 rounded border p-2 space-x-2 items-center justify-center"
                >
                  <BiEnvelope size={20} /> <span>Contato</span>
                </button>
                {/* <button type="button" className="flex flex-col w-1/2 rounded border p-2 items-center justify-center">
                  <div className="flex items-center">
                    <BiPhone /> SAC
                  </div>
                  <b className="text-xs">0800-0000-0000</b>
                </button> */}
              </div>
            </div>

            <div className="pt-3">
              <hr />
            </div>

            <div className="flex items-center justify-center text-xs pt-2 space-x-2">
              <Link to="/login" className="ml-1 text-blue-500">
                Voltar para login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
