import { ReactNode, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { validateUserPermissions } from '../utils/validateUserPermissions';

interface IPrivateRoute {
  permissions?: string[];
  roles?: string[];
  redirectTo?: string;
  children: ReactNode;
}

export function PrivateRoute({ permissions, roles, redirectTo = '/login', children }: IPrivateRoute) {
  const { isAuthenticated, user } = useContext(AuthContext);
  const location = useLocation();
  const { hasAllPermissions } = validateUserPermissions({
    user,
    permissions,
    roles,
  });

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });

  if (!isAuthenticated) {
    if (location.pathname !== '/login' && location.pathname !== '/') {
      sessionStorage.setItem('return', location.pathname);
    }

    return <Navigate to={redirectTo} />;
  }

  if (!hasAllPermissions) {
    return <Navigate to="/" />;
  }

  return (
      <>{children}</>
  );
}
