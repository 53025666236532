export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

export function FormatNumber(value: any) {
  try {
    if (isNaN(value)) return 0;
    return Intl.NumberFormat('pt-BR').format(Math.ceil(value));
  } catch (e) {
    return isNaN(value) ? 0 : value;
  }
}

export function abridgedControl(fullName: string, limit: number) {
  if (fullName.length > limit) {
    return toAbridged(fullName);
  }
  return fullName;
}

function toAbridged(fullName: string) {
  const token = '.';
  const separator = ' ';
  const names = removePrepositions(fullName).split(separator);
  const firstName = names[0];
  let surnames = '';
  names
    .filter((name: any, index: any) => index)
    .map((name: string) => {
      return (surnames += `${separator}${name.charAt(0)}${token}`);
    });
  return `${firstName}${surnames.toUpperCase()}`;
}

function removePrepositions(fullName: string) {
  return fullName.replace(/ dos| das| dos| das| de| d'/gi, '');
}
