import { useContext, useEffect, useState } from "react";
import { AuthContext } from "context/AuthContext";

import Logo from "assets/img/logo2.png";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { BiDesktop, BiError, BiMobileAlt } from "react-icons/bi";
import { centeredContent, whiteBox } from "styles";
import { Label, TextInput, Button, Alert } from "flowbite-react";

import "./styles.scss";

interface IFormInput {
  username: string;
  password: string;
}

export function Login() {
  const navigate = useNavigate();

  const { control, register, getValues, handleSubmit } = useForm<IFormInput>();
  const [submitLabel, setSubmitLabel] = useState("ENTRAR");
  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);
  const { loginWithToken, login } = useContext(AuthContext);

  function handleSignIn(data: IFormInput, e: any) {
    setLoading(true);
    doLogin(`${parseInt(data.username)}`, data.password);
  }

  function handleSignInError(error: any) {
    console.error(error);
  }

  async function doLogin(username: string, password: string) {
    try {
      setErrorMessage("");
      await login({ username, password });
    } catch (error: any) {
      setLoading(false);
      setInvalidUser(true);
      setErrorMessage(error.message);
    }
  }

  async function doLoginWithToken(token: string) {
    try {
      await loginWithToken(token);
    } catch (e) {
      setLoading(false);
      setInvalidUser(true);
    }
  }

  return (
    <div
      id="login-wrapper"
      className="w-full pt-48 md:pt-0 min-h-screen flex-1 flex flex-col md:flex-row 2xl:items-center justify-end"
    >
      <div className={`${whiteBox} 2xl:mr-[10%]`}>
        <img src={Logo} alt="Logo" className="h-32 2xl:h-48" />
        <form
          className="w-full mt-2 space-y-4"
          onSubmit={handleSubmit(handleSignIn, handleSignInError)}
        >
          <p className="pt-3 text-center">
            Informe seu CPF e senha para acessar.
            <br />
            ou
            <Link to="/register" className="ml-1 text-blue-500">
              faça seu cadastro aqui
            </Link>
            .
          </p>

          <div className="space-y-4">
            <div className="relative">
              <Label>CPF</Label>
              <TextInput
                {...register("username", {
                  onChange: (e) =>
                    (e.target.value = e.target.value.replace(/\D+/g, "")),
                })}
                id="username"
                name="username"
                type="text"
                autoComplete="false"
                placeholder="Digite seu CPF"
                maxLength={11}
                required
                onKeyUp={() => {
                  setErrorMessage("");
                }}
              />
            </div>

            {/* {showPassword && ( */}
            <div>
              <div className="relative">
                <Label>Senha</Label>
                <TextInput
                  {...register("password")}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="false"
                  required
                  placeholder="Digite sua senha"
                  title="Senha"
                  onKeyUp={() => setErrorMessage("")}
                />
              </div>
              <div className="flex items-start justify-end text-xs pt-2">
                <div className="pb-4">
                  <Link
                    to="/forgot-password"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Esqueceu sua senha?
                  </Link>
                </div>
              </div>
            </div>
            {/* )} */}
          </div>

          {!!errorMessage && (
            <Alert color="failure" icon={BiError}>
              {errorMessage}
            </Alert>
          )}

          <div className="flex flex-col justify-center items-center space-y-1">
            <Button
              type="submit"
              disabled={loading}
              className="w-full bg-blue-light"
            >
              {loading ? "Aguarde..." : submitLabel}
            </Button>
          </div>

          <div className="flex items-center justify-center text-xs pt-2 space-x-2">
            Primeiro acesso?
            <Link to="/register" className="ml-1 text-blue-500">
              Cadastre-se
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
