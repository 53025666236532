import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";

import achievementTitle from "assets/img/achievement-title.png";
import trophy from "assets/img/trophy.svg";

import { Button } from "flowbite-react";
import "./styles.scss";

export function AchievementModal(props: any) {
  // const modalID = "achievement";

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const { refreshUser, user } = useAuth();
  // const { openModal, closeModal } = useContext(ModalContext);
  // const { children } = props;

  ReactModal.setAppElement("#modal-achievement");

  function handleCloseModal() {
    setIsOpen(false);
  }

  function afterOpenModal() {
    //
  }

  useEffect(() => {
    const listener = async ({ data }: any) => {
      if (data.type === "achievement") {
        setIsOpen(true);
        setModalData(data.value);
        refreshUser();
      }
    };
    window.addEventListener("message", listener);
    return () => {
      window.removeEventListener("message", listener);
    };
  }, [refreshUser]);

  // useEffect(()=>{
  // 	console.log(modalData);
  // },[modalData]);

  if (!user) return null;

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            <div className="bg-white rounded-xl p-3 py-5 flex flex-col items-center flex-none mx-5 min-w-[315px] md:w-[400px]">
              <div className="text-center">
                <img
                  src={achievementTitle}
                  alt="Parabéns"
                  className="max-w-[250px] md:max-w-auto"
                />
              </div>

              <div className="text-gray-500 text-center mb-2 py-2 w-full">
                <p className="leading-tight py-2">
                  Você cumpriu o primeiro desafio
                  {/* <br />e garantiu seu prêmio. */}
                </p>
                {/* <p className="achievement-name font-bold text-blue-dark text-xl py-6 pt-7 md:pt-8 pl-8 md:ml-8 mr-4 md:mr-10">
                  KIT MESA
                </p>
                <p className="text-blue-light">
                  Seu kit será entregue na sua unidade.
                </p> */}
              </div>

              {modalData?.name && (
                <p className="text-orange-500 font-bold text-center">
                  {modalData?.name}
                </p>
              )}

              <div className="relative w-[200px]">
                {/* <Lottie animationData={badgeAnimation} loop={true} /> */}
                <img
                  src={trophy}
                  alt="Parabéns"
                  className="h-48 w-full object-contain"
                />
              </div>

              {/* <img src={achievementImage} alt="Parabéns" className="w-full" /> */}

              <div className="py-3 relative z-10">
                <Button type="button" onClick={() => handleCloseModal()}>
                  FECHAR
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}
