/* eslint-disable react-hooks/exhaustive-deps */

import { useAuth } from "context/AuthContext";
import { Outlet } from "react-router";
import { centeredContent } from "styles";
import { PromoVideo } from "components/video/promo";
import { ShirtPicker } from "./components/shirt-picker";
import { UserInfo } from "components/user-info";
import { RegulamentoFooter } from "components/regulamento-footer";

import "./styles.scss";
import { Link, NavLink } from "react-router-dom";
import { NavMenu } from "components/navmenu";
import { Helmet } from "react-helmet-async";
import { AvatarChallengeModal } from "components/modal/avatar-challenge";
import { VideoModal } from "components/modal/video";
import { useEffect, useState } from "react";
import { useModal } from "context/ModalContext";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { DetailChallengeModal } from "../Challenges/Components/modal-detail";
import {
  getChallenges,
  getAllChallenges,
  getChallengesLimit,
  getMyChallenges,
} from "services/challenges.service";
import { updateUserProfile } from "services/profile.service";
import { getBanner, getAllBanners } from "services/home.service";
import { ChatModal } from "components/modal/chat";
import moment from "moment";
import "moment/locale/pt-br";

import Person from "assets/img/person-icon.svg";
import TrophyLine from "assets/img/trophy-line.svg";
import Task from "assets/img/task-icon.svg";
import Target from "assets/img/target-icon.svg";
import Concluido from "assets/img/concluido.png";
import TrophyDark from "assets/img/trophy-dark.svg";
import Novo from "assets/img/novo.png";
import IconCardLg from "assets/img/cards-icon.svg";
import IconStarLg from "assets/img/star-icon.svg";
import BateuLevou from "assets/img/bateu-levou.png";
import Regularidade from "assets/img/regularidade.png";
import Superacao from "assets/img/superacao.png";
import IconVideo from "assets/img/icon-play.png";
import trophy from "assets/img/taça.png";
import Reward from "assets/img/icon-reward.svg";
import Graph from "assets/img/icon-graph.svg";
import Medal from "assets/img/icon-medal.svg";
import { TabItem } from "flowbite-react/lib/esm/components/Tab/TabItem";
import { FaCheck } from "react-icons/fa";
import { GraphItem } from "./components/graph-item";
import { DateItem } from "./components/data-item";
import { PeriodItem } from "./components/period-item";
import ChallengeCard from "pages/Challenges/Components/challenge-card";
import Transmissao from "assets/img/transmissao.jpeg";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";

moment.locale("pt-br");

export function Home() {
  const { user, refreshUser } = useAuth();
  const { showModal } = useModal();
  const [challenges, setChallenges] = useState<any>();
  const [banner, setBanner] = useState<any>();
  const comparaData = new Date().getTime();
  const [loading, setLoading] = useState<boolean>(false);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3.2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const responsive2 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  useEffect(() => {
    setLoading(true);

    if (user) {
      // console.log(user.challenges);
      const completed = user.extra.avatar || user.image;
      if (!completed) {
        showModal("avatar-challenge");
      }

      loadChallenges();
      loadBanners();

      if (user.extra.firstLogin === undefined) {
        user.extra.firstLogin = false;
        updateUserProfile(user);
      }
    }
  }, [user]);

  useEffect(() => {
    refreshUser();
  }, []);

  async function loadChallenges() {
    const data = await getMyChallenges("GT35", user?._id);

    //setChallenges([]);
    setChallenges(
      data.filter((item: any) => {
        return item.vigente === true;
      })
    );

    setLoading(false);
  }

  async function loadBanners() {
    const data = await getAllBanners();
    setBanner(data);
  }

  function showDetailChallenge(obj: any) {
    showModal("modal-detail-challenge", { config: obj });
  }

  function showDetailBanner(obj: any) {
    showModal("modal-video", { config: obj });
  }

  if (!user) return null;

  return (
    <>
      <Helmet>
        <title>VAI PRA CIMA, BRB!</title>
      </Helmet>
      <div id="home-wrapper" className="min-h-screen">
        <div className="z-10 relative flex flex-col items-center space-y-0  pb-14 md:p-10">
          <div className="md:space-y-10 w-full flex flex-col items-center mb-16">
            <NavMenu />

            <UserInfo />
          </div>

          <div className="home">
            {banner && banner.length > 0 && (
              <section className="w-full  bg-[#062165]">
                <div className="md:mx-auto md:w-[1200px]">
                  <ResponsiveCarousel
                    showArrows={true}
                    showThumbs={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={10000}
                  >
                    {banner.map((item: any, i: number) => (
                      <div key={i}>
                        <img src={item.image.original.url} alt={item.title} />
                      </div>
                    ))}
                  </ResponsiveCarousel>
                </div>
              </section>
            )}

            <section className="w-screen py-10 px-3 md:px-0 bg-gradient-section">
              <div className="w-full md:mx-auto md:w-[1200px] md:flex">
                <div className="w-full md:w-[60%] md:mr-5">
                  <div className="desafios h-full">
                    <h2>
                      <strong>Desafios</strong> para você
                    </h2>
                    {loading && (
                      <p className="text-center noChallenges">Carregando...</p>
                    )}
                    {!loading && challenges ? (
                      <Carousel
                        className="mr-[-24px] md:mr-0"
                        responsive={responsive}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                      >
                        {challenges?.map((item: any) => (
                          <ChallengeCard data={item} />
                        ))}
                      </Carousel>
                    ) : (
                      <></>
                    )}
                    {!loading && challenges?.length === 0 ? (
                      <div className="not-result flex">
                        <p className="text-center mt-10  border p-14 uppercase text-xl font-bold noChallenges w-4/5">
                          Você ainda não recebeu desafios
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div className="w-full md:w-[40%]">
                  <div className="meusPremios">
                    <h2>
                      Seus <strong>prêmios</strong>
                    </h2>
                    <div className="contentCards">
                      <div className="item">
                        <img src={IconStarLg} alt="" />
                        <h3>
                          <strong>Estrelas</strong>
                        </h3>
                        <p className="pl-3 pr-3">
                          As suas estrelas podem virar ótimas experiências
                        </p>
                        <NavLink to="/experiencias" className="">
                          VER ESTRELAS
                        </NavLink>
                      </div>
                      <div className="item">
                        {/* <span>9+</span> */}
                        <img src={IconCardLg} alt="" />
                        <h3>
                          <strong>Mundo BRB</strong>
                        </h3>
                        <p>
                          Veja os pontos que você já conquistou <br /> e troque
                          por prêmios
                        </p>
                        <NavLink
                          to="https://mundo.brb.com.br/home"
                          className=""
                          target="_blank"
                        >
                          VER PONTOS
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* <section className="py-10 px-3 md:p-10 w-full space-y-5 mx-auto md:w-[1200px]">
              <h3 className="text-[#00ADEE] text-3xl uppercase font-bold mb-10">
                são muitas <span className="text-[#88E1F2]">chances </span>
                de ganhar
              </h3>

              <div className="md:flex ">
                <div className="w-full md:w-[60%] mb-10 md:mb-0 mr-5">
                  <GraphItem />
                </div>
                <div className="w-full md:w-[40%]">
                  <DateItem />
                </div>
              </div>

              <div className="w-full h-full">
                <PeriodItem />
              </div>
            </section> */}

            {/* <div className="w-full md:w-[1200px] home block md:flex">
              <div className="w-full md:w-[60%] left p-4 md:p-8">
                <div className="premios flex md:hidden">
                  <div>
                    <img src={Reward} alt="" />
                    <p className="-mt-1">Meus Premios</p>
                  </div>
                  <div>
                    <img src={Graph} alt="" />
                    <p className="mt-1">A campanha</p>
                  </div>
                  <div>
                    <img src={Medal} alt="" />
                    <p className="mt-1">Formas de ganhar</p>
                  </div>
                </div>

                <div className="missao">
                  <h2>
                    <strong>Formas</strong> de ganhar
                  </h2>
                  <div className="mr-[-24px] md:mr-0 contentCards overflow-x-auto md:overflow-x-hidden">
                    <div className="item">
                      <img src={BateuLevou} alt="" />
                      <h3>Bateu, Levou!</h3>
                      <p>
                        Ficar no azul no portal de metas, prêmio 3400 pontos do
                        Mundo BRB para a Unidade e você ganha um Card
                      </p>
                    </div>
                    <div className="item">
                      <img src={Regularidade} alt="" />
                      <h3>Regularidade</h3>
                      <p>
                        Manter-se no azul por mais de dois meses, vale mais 3400
                        pontos do Mundo BRB para Unidade e mais um Card para
                        você
                      </p>
                    </div>
                    <div className="item">
                      <img src={Superacao} alt="" />
                      <h3>Superação 110% </h3>
                      <p>
                        Ficar com 110% no portal de metas, prêmio bonus de 6800
                        pontos do Mundo BRB para a Unidade e você ganha 10
                        estrelas
                      </p>
                    </div>
                  </div>
                </div>
                <div className="videoCampanha">
                  {banner && (
                    <>
                      {banner.image && banner.image.original && (
                        <img src={banner.image.original.url} alt="imagem" />
                      )}
                      <div>
                        <p>{banner.title}</p>
                        {banner.isVideo ? (
                          <button
                            onClick={() => {
                              showDetailBanner(banner);
                            }}
                          >
                            <img src={IconVideo} alt="" />
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              window.open(banner.link);
                            }}
                            className="btnSaibaMais"
                          >
                            Saiba Mais
                          </button>
                        )}
                      </div>
                    </>
                  )}
                  <div>
                  <p>
                    Saiba tudo sobre a campanha <br />{" "}
                    <strong>"Vai pra cima BRB"</strong>
                  </p>
                  <button
                    onClick={() => {
                      showModal("modal-video");
                    }}
                  >
                    <img src={IconVideo} alt="" />
                  </button>
                </div>
                </div>
              </div>
              <div className="w-full md:w-[40%] right p-4 md:p-8">
                <div className="performance">
                  <h2>
                    Desempenho no Placar <strong>BRB</strong>
                  </h2>
                  <div className="contentItems">
                    <Carousel
                      responsive={responsive2}
                      showDots={true}
                      arrows={false}
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                    >
                      <div className="item">
                        <h3>
                          Primeiro <strong>trimestre</strong>
                        </h3>
                        <div className="contentProgress">
                          <div className="progress1">
                            <div
                              className="progress"
                              style={{ width: "50%" }}
                              title="1,4k"
                            >
                              <span>1,4k</span>
                              <div className="triangle-right"></div>
                            </div>
                            <span>3,5k</span>
                            <div className="triangle-right blue"></div>
                          </div>
                          <div className="progress2">
                            <img src={trophy} alt="Parabéns" />
                            <span>8,4k</span>
                            <div className="triangle-right"></div>
                          </div>
                        </div>

                        <h3>
                          Primeiro <strong>semestre</strong>
                        </h3>
                        <div className="contentProgress">
                          <div className="progress1">
                            <div
                              className="progress"
                              style={{ width: "10%" }}
                              title="0,8k"
                            >
                              <span>0,8k</span>
                              <div className="triangle-right"></div>
                            </div>
                            <span>3,5k</span>
                            <div className="triangle-right blue"></div>
                          </div>
                          <div className="progress2">
                            <img src={trophy} alt="Parabéns" />
                            <span>8,4k</span>
                            <div className="triangle-right"></div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <h3>
                          Primeiro <strong>trimestre</strong>
                        </h3>
                        <div className="contentProgress">
                          <div className="progress1">
                            <div
                              className="progress"
                              style={{ width: "0%" }}
                              title="0k"
                            >
                              <div className="triangle-right"></div>
                            </div>
                            <span>3,5k</span>
                            <div className="triangle-right blue"></div>
                          </div>
                          <div className="progress2">
                            <img src={trophy} alt="Parabéns" />
                            <span>8,4k</span>
                            <div className="triangle-right"></div>
                          </div>
                        </div>

                        <h3>
                          Primeiro <strong>semestre</strong>
                        </h3>
                        <div className="contentProgress">
                          <div className="progress1">
                            <div
                              className="progress"
                              style={{ width: "0%" }}
                              title="0k"
                            >
                              <div className="triangle-right"></div>
                            </div>
                            <span>3,5k</span>
                            <div className="triangle-right blue"></div>
                          </div>
                          <div className="progress2">
                            <img src={trophy} alt="Parabéns" />
                            <span>8,4k</span>
                            <div className="triangle-right"></div>
                          </div>
                        </div>
                      </div>
                    </Carousel>
                  </div>
                </div>
                <div className="proximosDesafios">
                <div>
                  <p>Converse com outros participantes</p>
                  <button
                    type="button"
                    className=""
                    onClick={() => {
                      showModal("modal-chat");
                    }}
                  >
                    Abrir chat
                  </button>
                </div>
              </div>
                <div className="proximosDesafios">
                  <div>
                    <p>Confira as formas de ganhar!</p>
                    <Link to="/como-ganhar">
                      <button type="button" className="">
                        Saiba mais
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <RegulamentoFooter />
      </div>
      <Outlet />
      <AvatarChallengeModal />
      <VideoModal />
      <DetailChallengeModal />
      <ChatModal />
    </>
  );
}
