import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";

import { useModal } from "context/ModalContext";
import { Button } from "flowbite-react";
import { Link, NavLink } from "react-router-dom";

import "./styles.scss";

export function DetailCardsModal() {
  const { user } = useAuth();
  const { openModal, modalData, showModal, closeModal } = useModal();

  const modalID = "modal-detail-cards";

  const [modalIsOpen, setIsOpen] = useState(false);

  ReactModal.setAppElement("#modal");

  function handleCloseModal() {
    closeModal();
  }

  function afterOpenModal() {
    //
  }

  function handleShowAvatar() {
    //
  }

  useEffect(() => {
    if (!user) return;
    setIsOpen(openModal === modalID);
  }, [user, openModal, modalData]);

  if (!user) return null;

  if (!modalData || modalData?.length < 1) return null;

  const item = modalData.config;

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-blue-dark backdrop-blur-sm bg-opacity-90 flex flex-col items-center justify-center"
        contentElement={() => (
          <div id="modalDetailCards">
            <div className="bg-white p-1 flex flex-col items-center flex-none mx-5">
              <Button
                className="btnClose"
                type="button"
                onClick={() => handleCloseModal()}
              >
                X
              </Button>
              <h3 className="text-lg text-blue-500 font-bold">
                Partes do card
              </h3>

              <div className="md:w-[900px] text-center">
                {item?.map((obj: any, i: number) => (
                  <div className="front" key={i}>
                    <img
                      src={obj.imgPremio}
                      className="bgPremio"
                      alt="premio"
                    />
                    <img
                      src={obj.imgCard.image.medium.url}
                      className=""
                      alt="premio-card"
                    />
                    {!obj.my && (
                      <img src="img/block.png" alt="" className="blockDetail" />
                    )}
                    <div className="presenteVerde">
                      {obj.my > 0 ? obj.my : 0}
                    </div>
                    <div className="infoObjective detailInfo">
                      <h2 className="titleObjectives">
                        {obj.my ? (
                          obj.type === "funcionario" ? (
                            <b>Você Conquistou!</b>
                          ) : (
                            <b>Sua Unidade Conquistou!</b>
                          )
                        ) : obj.type === "funcionario" ? (
                          <b>Você Pode Alcançar</b>
                        ) : (
                          <b>Sua Unidade Pode Alcançar</b>
                        )}
                      </h2>
                      {obj.my ? (
                        obj.type === "funcionario" ? (
                          <>
                            <p>Você possui {obj.my} card(s) desta peça.</p>
                            <Link to="/extrato">
                              <button className="btnOrange no-lock">
                                VER EXTRATO
                              </button>
                            </Link>
                          </>
                        ) : (
                          <>
                            <p>
                              Sua unidade possui {obj.my} card(s) desta peça.
                            </p>
                            <Link to="/extrato?unidade=true">
                              <button className="btnOrange no-lock">
                                VER EXTRATO
                              </button>
                            </Link>
                          </>
                        )
                      ) : obj.type === "funcionario" ? (
                        <>
                          <p>
                            Você ainda não tem esse card. Supere mais objetivos
                            e tenha a chance de conseguir.
                          </p>
                          <button className="btnDisabled disabled">
                            <span className="lock1"></span>
                            BLOQUEADO
                            <span className="lock2"></span>
                          </button>
                        </>
                      ) : (
                        <>
                          <p>
                            Sua unidade ainda não tem esse card. Supere mais
                            objetivos e tenha a chance de conseguir.
                          </p>
                          <button className="btnDisabled disabled">
                            <span className="lock1"></span>
                            BLOQUEADO
                            <span className="lock2"></span>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}
