import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { getAllDicas } from "services/dicas.service";
import { Scrollbars } from "react-custom-scrollbars-2";

import { useModal } from "context/ModalContext";
import { Button } from "flowbite-react";
import moment from "moment";
import "moment/locale/pt-br";

import "./styles.scss";

moment.locale("pt-br");

export function JukaModal() {
  const { user } = useAuth();
  const { openModal, modalData, showModal, closeModal } = useModal();
  const [dicas, setDicas] = useState<any>();

  const modalID = "modal-juka";

  const [modalIsOpen, setIsOpen] = useState(false);

  ReactModal.setAppElement("#modal");

  function handleCloseModal() {
    closeModal();
  }

  function afterOpenModal() {
    //
  }

  function handleShowAvatar() {
    //
  }

  useEffect(() => {
    if (!user) return;
    loadData();
    setIsOpen(openModal === modalID);
  }, [user, openModal, modalData]);

  async function loadData() {
    const data = await getAllDicas();
    setDicas(data);
  }

  if (!user) return null;

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-blue-dark backdrop-blur-sm bg-opacity-90 flex flex-col items-center justify-center"
        contentElement={() => (
          <div id="modalJuka">
            <div className="bg-white rounded-xl p-3 flex flex-col items-center flex-none mx-5">
              <Button
                className="btnClose"
                type="button"
                onClick={() => handleCloseModal()}
              >
                X
              </Button>

              <h3 className="text-lg text-blue-500 font-bold mb-8">
                Dicas do Juka
              </h3>

              <div className="w-[330px] h-[500px] md:w-[500px] md:h-[400px]  space-y-16 md:space-x-6 md:space-y-0 md:items-start md:justify-center">
                <Scrollbars
                  style={{ width: "100%" }}
                  className="contentDicas"
                  renderTrackVertical={(props) => (
                    <div {...props} className="track-vertical" />
                  )}
                >
                  <ul className="listDicas">
                    {dicas.map((item: any) => (
                      <li key={item._id}>
                        <p
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        ></p>
                        <i>{moment(item.created).format("DD/MM/yyyy")}</i>
                      </li>
                    ))}
                  </ul>
                </Scrollbars>
                <div className="flex-1 flex flex-col text-center"></div>
              </div>

              {/* <div className="py-3 relative z-10 w-full flex flex-col space-y-2">
                <Button
                  className="bg-blue-light"
                  type="button"
                  onClick={() => handleCloseModal()}
                >
                  FECHAR
                </Button>
              </div> */}
            </div>
          </div>
        )}
      />
    </>
  );
}
