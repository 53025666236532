import { api } from './api';

type SignInRequestData = {
  username: string;
  password: string;
  apiKey?: string;
  grant_type?: string;
};

export function signInRequest(data: SignInRequestData) {
  data['apiKey'] = process.env.REACT_APP_APIKEY;
  data['grant_type'] = 'password';

  return api
    .post('/auth/token', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.error(error.response.data.message);
      throw new Error('Dados incorretos ou CPF não cadastrado.'); //error.response.data;
    });
}

export function fetchUser() {
  return api
    .get('/player/me/status')
    .then((response) => {
      const user = response.data;
      if (user) {
        return user;
      } else {
        document.dispatchEvent(new CustomEvent('signout'));
      }
    })
    .catch((error) => {
      // console.log(error);
    });
}

export function registerUser(data: any) {
  const payload = {
    _id: `${parseInt(data.username)}`,
    matricula: data.code,
    name: `${data.first_name}`,
    email: data.email,
    password: data.password,
    extra: {
      mobile: data.phone,
      first_name: data.first_name,
      last_name: data.last_name || '',
    },
  };

  return api
    .put('/database/player_register__c', payload)
    .then((response) => {
      const user = response.data;
      return user;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

export async function validateUser(payload: any) {
  try {
    const response = await api.put('/database/player_validate__c', payload);
    if (response.data.status === 'Unauthorized') {
      throw new Error(response.data.message);
    } else {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function recoverPassword(id: string) {
  try {
    const response = await api.get(`/player/password/change?player=${id.toLocaleLowerCase().trim()}`);
    if (response.data.status === 'Unauthorized') {
      throw new Error(response.data.message);
    } else {
      return response.data;
    }
  } catch (error: any) {
    throw error;
  }
}

export async function updatePassword(payload: any) {
  try {
    const { username, password, code } = payload;
    const response = await api.put(
      `/player/password?player=${username.toLocaleLowerCase().trim()}&new_password=${password}&code=${code}`
    );
    if (response.data.type === 'OK') {
      return true;
    } else {
      throw new Error('It was not possible to update your password');
    }
  } catch (error: any) {
    throw new Error('It was not possible to update your password');
  }
}
