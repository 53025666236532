import { useAuth } from "context/AuthContext";
import { BiLogOut } from "react-icons/bi";
import { Link, NavLink } from "react-router-dom";
import trophy from "assets/img/trophy-icon.svg";
import { useEffect, useState } from "react";
import { getNomeUnidade, getPointsUnidade } from "services/profile.service";
import { actionLog } from "services/actionlog.service";
import { FaExternalLinkAlt } from "react-icons/fa";
import { getNovasEntregas } from "services/achievement.service";

require("./styles.scss");

export function NavMenu() {
  const { user } = useAuth();
  const { signOut } = useAuth();
  const [nomeUnidade, setNomeUnidade] = useState<any>();
  const [pontosUnidade, setPointsUnidade] = useState<any>();
  const [totalEntregas, setTotalEntragas] = useState<any>();

  function handleLinkClass({ isActive }: any) {
    const baseClass = isActive
      ? "active text-white text-center py-1 md:px-2 md:py-2"
      : "bg-opacity-30 text-white text-center py-1 md:px-2 md:py-2 ";
    return `${baseClass} hover:text-white no-underline`;
  }

  async function loadData() {
    if (!user?.extra?.unidade) {
      return;
    }
    const data = await getNomeUnidade(user?.extra.unidade);
    setNomeUnidade(data[0].nome);

    // const data1 = await getPointsUnidade(user?.extra.unidade);
    // const pts = data1[0] ? data1[0].total : 0;

    // setPointsUnidade(pts);
  }

  async function loadTotalEntregas() {       
    const data = await getNovasEntregas(user?._id);
    const total = data[0] ? data[0].total : 0;
    setTotalEntragas(total);
  }

  function handleActionLog(data: any) {
    actionLog("interaction", data);
  }

  useEffect(() => {
    if (!user) return 
    loadData();
    loadTotalEntregas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="hidden md:flex md:justify-end md:w-[100%] md:max-w-[1200px]">
      <div id="container-menu" className="w-full">
        <div className="w-[50%] flex md:flex md:items-center justify-evenly unidade">
          <p>
            <strong className="leading-none">
              {nomeUnidade ? nomeUnidade : "----"}
            </strong>
          </p>
          {/* <Link className="points" to="/extrato?unidade=true">
            <p className="w-full uppercase">
              <img className="h-5" src={trophy} alt="Parabéns" />{" "}
              <strong>{pontosUnidade ? pontosUnidade : 0}</strong> pontos mundo
              BRB
            </p>
          </Link> */}
          <Link
            className="points"
            to="https://mundo.brb.com.br/home"
            target="_blank"
          >
            <p className="w-full ">
              <FaExternalLinkAlt />
              Mundo BRB
            </p>
          </Link>
        </div>
        <div className="w-[70%] grid grid-cols-1 md:grid-cols-5 gap-1 md:gap-4 md:grid-cols-auto md:flex md:items-center justify-end">
          <NavLink
            to="/campanha"
            className={handleLinkClass}
            onClick={() =>
              handleActionLog({
                itemId: "aCampanha",
                itemTitle: "A CAMPANHA",
                type: "page_view_custom",
              })
            }
          >
            A campanha
          </NavLink>
          {/* <NavLink to="/como-ganhar" className={handleLinkClass}>
            Formas de ganhar
          </NavLink> */}
          <NavLink
            to="/extrato"
            className={handleLinkClass}
            onClick={() =>
              handleActionLog({
                itemId: "extrato",
                itemTitle: "EXTRATO",
                type: "page_view",
              })
            }
          >
            Extrato
          </NavLink>
          {/* <NavLink
            to="/resgates"
            className={handleLinkClass}
            onClick={() =>
              handleActionLog({
                itemId: "resgates",
                itemTitle: "RESGATES",
                type: "page_view",
              })
            }
          >
            Resgates
          </NavLink> */}
          <NavLink
            to="/contato"
            className={handleLinkClass}
            onClick={() =>
              handleActionLog({
                itemId: "ajuda",
                itemTitle: "AJUDA",
                type: "page_view",
              })
            }
          >
            Ajuda
          </NavLink>
          <NavLink
            to="/minhas-entregas"
            className={`${handleLinkClass} relative`}
            onClick={() =>
              handleActionLog({
                itemId: "minhasEntregas",
                itemTitle: "MINHAS ENTREGAS",
                type: "page_view",
              })
            }
          >
            Minhas Entregas
            {totalEntregas > 0 && (
              <span className="total">{totalEntregas}</span>
            )}
          </NavLink>
          {/* <NavLink to="/faq" className={handleLinkClass}>
          FAQ
        </NavLink> */}
          {/* <NavLink to="/regulamento" className={handleLinkClass}>
        Regulamento
      </NavLink> */}
          {/* <NavLink
    to="/ganhadores"
    className={({ isActive }) =>
      isActive
        ? 'bg-blue-dark text-white px-4  py-2 font-bold rounded ring-1 ring-blue-dark'
        : 'text-white border-white px-4  py-2 font-bold rounded ring-1 ring-white'
    }
  >
    GANHADORES
  </NavLink> */}
          {/* <NavLink to="/contato" className={handleLinkClass}>
        CONTATO
      </NavLink> */}

          <button
            type="button"
            onClick={() => signOut()}
            className={handleLinkClass({ isActive: false })}
          >
            <div className="flex items-center justify-center space-x-2  ring-1 ring-white px-3 ">
              <div>SAIR</div>
              <div>
                <BiLogOut
                  size={16}
                  className="text-blue-400 transform rotate-180"
                />
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
