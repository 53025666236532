import { useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import Avatar, { genConfig } from "funifier-nice-avatar";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { useModal } from "context/ModalContext";
import { uploadPicture, updateUserProfile } from "services/profile.service";
import { useAuth } from "context/AuthContext";
import { toast } from "react-toastify";
import { BiCamera, BiTrash } from "react-icons/bi";
import { FaCamera, FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { AvatarConfig, GetAvatarDefaultOptions } from "./config";
import { Button } from "flowbite-react";
import { AiOutlinePicture } from "react-icons/ai";
import { IoMdShirt } from "react-icons/io";
import { actionLog } from "services/actionlog.service";

import "./styles.scss";

const config = genConfig();

const avatarDefaultOptions = GetAvatarDefaultOptions() as any;

let imagePreviewTimeout: any = null;

export function AvatarModal(props: any) {
  // const modalID = "achievement";

  const { user, refreshUser } = useAuth();

  const modalID = "avatar";

  const [loading, setLoading] = useState(false);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [avatarConfig, setAvatarConfig] = useState<AvatarConfig>(config);

  const { openModal, modalData, closeModal } = useModal();

  const [avatarType, setAvatarType] = useState(0);

  const [image, setImage] = useState("");
  const editor = useRef<any>(null);
  const inputRef = useRef<any>(null);

  ReactModal.setAppElement("#modal");

  function handleCloseModal() {
    setImage("");
    setLoading(false);
    setAvatarType(0);
    closeModal();
  }

  function afterOpenModal() {}

  async function handleUpload() {
    let profileImage: any = {};

    setLoading(true);

    if (image) {
      const dataUrl = editor.current.getImageScaledToCanvas().toDataURL();
      if (dataUrl) {
        const uploadResult = await uploadPicture(dataUrl);
        if (uploadResult) {
          const url = uploadResult.url;
          profileImage = {
            image: {
              small: { url },
              medium: { url },
              original: { url },
            },
          };
        }
      }
    }

    profileImage["extra"] = {
      ...user?.extra,
      avatar_type: avatarType,
      avatar: avatarConfig,
    };

    if (profileImage) {
      // console.log(profileImage);
      if (!user) return;
      await updateUserProfile({ _id: user._id, ...profileImage });
      await actionLog("update_avatar");
      refreshUser();
      handleCloseModal();
      toast(`👍 Seu perfil foi atualizado com sucesso!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function handleImageChange() {
    if (imagePreviewTimeout) {
      clearTimeout(imagePreviewTimeout);
    }

    imagePreviewTimeout = setTimeout(() => {
      getImageUrl();
    }, 1000);
  }

  function handleClearImage() {
    const profileImage = document.getElementById("profile-image");
    if (profileImage) {
    }
    setImage("");
  }

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const getImageUrl = async () => {
    if (!editor.current) return;
    const dataUrl = editor.current.getImageScaledToCanvas().toDataURL();
    const result = await fetch(dataUrl);
    const blob = await result.blob();

    const profileImage = document.getElementById("profile-image");
    if (profileImage) {
      profileImage.style.backgroundImage = `url(${window.URL.createObjectURL(
        blob
      )})`;
    }

    // return window.URL.createObjectURL(blob)
  };

  function getImagePlaceHolder() {
    if (!image && user && user.image && user.image.medium) {
      return { backgroundImage: `url(${user.image.medium.url})` };
    }

    return {};
  }

  // function switchAvatarPart(part: string) {
  //   const newAvatarConfig = { ...avatarConfig };
  //   console.log(newAvatarConfig);
  //   setAvatarConfig(newAvatarConfig);
  // }

  function switchAvatarPart(type: string, currentOpt?: any) {
    const opts: any = avatarDefaultOptions[type];
    const currentIdx = opts.findIndex((item: any) => item === currentOpt);
    const newIdx = (currentIdx + 1) % opts.length;
    const newAvatarConfig = { ...avatarConfig, [type]: opts[newIdx] };
    if (type === "hairStyle") {
      newAvatarConfig["hatStyle"] = "none";
    }
    setAvatarConfig(newAvatarConfig);
  }

  useEffect(() => {
    if (!user) return;
    setIsOpen(openModal === modalID);
    if (modalData && modalData.config) {
      setAvatarConfig(modalData.config);
      if (user.extra.avatar_type) {
        setAvatarType(user.extra.avatar_type);
      }
      if (user.extra.avatar) {
        setAvatarConfig(user.extra.avatar);
      }
    }
  }, [user, openModal, modalData]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-blue-dark backdrop-blur-sm bg-opacity-90 flex flex-col md:items-center justify-center modal-avatar"
        contentElement={() => (
          <div>
            <div className="md:w-full bg-white shadow-xl text-white rounded-xl px-5 py-10 flex flex-col items-center space-y-5 flex-none mx-5">
              <div className="flex flex-row space-x-3">
                <Button
                  size="sm"
                  pill
                  outline={avatarType !== 0}
                  onClick={() => setAvatarType(0)}
                >
                  AVATAR
                </Button>
                <Button
                  size="sm"
                  pill
                  outline={avatarType !== 1}
                  onClick={() => setAvatarType(1)}
                >
                  ENVIAR IMAGEM
                </Button>
              </div>

              {avatarType === 0 && (
                <div className="flex flex-col items-center">
                  <Avatar
                    className="w-36 h-36 border-blue-light border-2"
                    {...avatarConfig}
                  />

                  <div className="flex-1 mt-3 grid grid-cols-3 flex-wrap gap-1">
                    <Button
                      color="light"
                      size="sm"
                      pill
                      onClick={() =>
                        switchAvatarPart("faceColor", avatarConfig.faceColor)
                      }
                    >
                      Cor
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      pill
                      onClick={() =>
                        switchAvatarPart("eyeStyle", avatarConfig.eyeStyle)
                      }
                    >
                      Olhos
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      pill
                      onClick={() =>
                        switchAvatarPart(
                          "glassesStyle",
                          avatarConfig.glassesStyle
                        )
                      }
                    >
                      Óculos
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      pill
                      onClick={() =>
                        switchAvatarPart("earSize", avatarConfig.earSize)
                      }
                    >
                      Orelhas
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      pill
                      onClick={() =>
                        switchAvatarPart("mouthStyle", avatarConfig.mouthStyle)
                      }
                    >
                      Boca
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      pill
                      onClick={() =>
                        switchAvatarPart("noseStyle", avatarConfig.noseStyle)
                      }
                    >
                      Nariz
                    </Button>
                  </div>

                  <div className="flex-1 mt-1 grid grid-cols-2 flex-wrap gap-1">
                    <Button
                      color="light"
                      size="sm"
                      pill
                      onClick={() =>
                        switchAvatarPart("hairStyle", avatarConfig.hairStyle)
                      }
                    >
                      Cabelo
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      pill
                      onClick={() =>
                        switchAvatarPart("hairColor", avatarConfig.hairColor)
                      }
                    >
                      Cor do Cabelo
                    </Button>
                  </div>

                  <div className="flex-1 w-full mt-1 grid grid-cols-2 flex-wrap gap-1">
                    <Button
                      color="light"
                      size="sm"
                      pill
                      onClick={() =>
                        switchAvatarPart("hatStyle", avatarConfig.hatStyle)
                      }
                    >
                      Acessório
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      pill
                      className="px-2"
                      onClick={() =>
                        switchAvatarPart("hatColor", avatarConfig.hatColor)
                      }
                    >
                      Cor do Acc.
                    </Button>
                  </div>

                  <div className="flex-1 w-full mt-1 grid grid-cols-3 flex-wrap gap-1">
                    <Button
                      color="light"
                      size="sm"
                      pill
                      onClick={() =>
                        switchAvatarPart("shirtStyle", avatarConfig.shirtStyle)
                      }
                    >
                      Camisa
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      pill
                      onClick={() =>
                        switchAvatarPart("shirtColor", avatarConfig.shirtColor)
                      }
                    >
                      Cor
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      pill
                      onClick={() =>
                        switchAvatarPart("bgColor", avatarConfig.bgColor)
                      }
                    >
                      Fundo
                    </Button>
                  </div>
                </div>
              )}
              {avatarType === 1 && (
                <div id="avatarEditor">
                  <div className="rounded-full relative z-2 mt-2 rounded-lg bg-gray-800 w-64 h-64">
                    <div
                      className="relative z-20"
                      style={getImagePlaceHolder()}
                    >
                      <Dropzone
                        onDrop={(dropped: any) => setImage(dropped[0])}
                        multiple={false}
                        maxFiles={1}
                        noClick={!!image}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <AvatarEditor
                              ref={(e) => (editor.current = e)}
                              onImageChange={handleImageChange}
                              width={256}
                              height={256}
                              image={image}
                              borderRadius={256}
                              border={0}
                              className="overflow-hidden rounded-full"
                            />
                            <input {...getInputProps()} ref={inputRef} />
                          </div>
                        )}
                      </Dropzone>
                      {!!image && (
                        <button
                          onClick={handleClearImage}
                          className="absolute top-2 right-2 rounded-full bg-red-500 text-white flex items-center justify-center p-0 w-14 h-14"
                        >
                          <BiTrash size={24} />
                        </button>
                      )}
                    </div>
                    <div
                      className="editImage flex"
                      onClick={() => handleClick()}
                    >
                      <span>Mudar Foto</span>
                      <AiOutlinePicture size={20} className="" />
                    </div>
                    {!image && (
                      <AiOutlinePicture
                        size={64}
                        className="z-10 absolute top-0 left-0 right-0 bottom-0 m-auto pointer-events-none opacity-20"
                      />
                    )}
                  </div>
                </div>
              )}

              {loading && (
                <div className="px-4 py-2 font-bold flex flex-row items-center space-x-4 border border-white border-opacity-50 rounded-full">
                  SALVANDO ALTERAÇÕES...
                </div>
              )}

              {!loading && (
                <div className="flex flex-row items-center space-x-4">
                  <Button
                    color="failure"
                    pill
                    className="close"
                    onClick={() => handleCloseModal()}
                  >
                    <div className="flex space-x-2">
                      {/* <FaTimesCircle size={18} />  */}
                      <span>FECHAR</span>
                    </div>
                  </Button>{" "}
                  <Button
                    className="save"
                    onClick={() => handleUpload()}
                    color="success"
                    pill
                  >
                    <div className="flex space-x-2">
                      {/* <FaCheckCircle size={18} /> */}
                      <span>SALVAR</span>
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      />
    </>
  );
}
