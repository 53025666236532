import { NavBar } from 'components/navbar';
import { UIElementProps } from 'types/prop-types';

export function AppContainer({ children }: UIElementProps) {
  return (
    <div className="w-full flex flex-col min-h-screen">
      {/* <NavBar /> */}
      <>{children}</>
    </div>
  );
}
