import { useModal } from "context/ModalContext";
import { FC, useEffect, useState } from "react";

import Concluido from "assets/img/concluido.png";
import Conquistado from "assets/img/conquistado.png";
import Novo from "assets/img/novo.png";
import Apurado from "assets/img/apurado2.png";

import { User } from "types/user";
import moment from "moment";
import { useAuth } from "context/AuthContext";
import { actionLog } from "services/actionlog.service";

import "./style.scss";

interface Options {
  data: any;
}

const ChallengeCard: FC<Options> = ({ data }: Options) => {
  const { showModal } = useModal();
  const { user } = useAuth();

  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    // if(user?.challenges){
    //     const completed = user.challenges[data._id];
    //     if(!!completed){
    //         setIsCompleted(true);
    //     }
    // }
  }, []);

  function showDetailChallenge(obj: any) {
    showModal("modal-detail-challenge", { config: obj });
  }

  function handleActionLog(data: any) {
    actionLog("interaction", {
      itemId: data._id,
      itemTitle: data.challenge,
      type: "challenge",
    });
  }

  const handleChallengeName = (title: string) => {
    if (!title) return;
    if (title.length > 30) {
      const first = title.substring(0, title.indexOf(" "));
      let lasts = title.substring(title.indexOf(" ") + 1);

      lasts = lasts.substring(0, 23) + "...";
      return (
        <h3>
          {first} <br /> {lasts}
        </h3>
      );
    } else {
      const first = title.substring(0, title.indexOf(" "));
      const lasts = title.substring(title.indexOf(" ") + 1);

      return (
        <h3>
          {first} <br /> {lasts}
        </h3>
      );
    }
  };

  const handleUntilDate = (when: any) => {
    if (when && when.startDateTime && when.endDateTime) {
      const inicio = moment(when.startDateTime).format("DD");
      const fim = moment(when.endDateTime).format("DD");
      const mes1 = moment(when.startDateTime).format("MMM");
      const mes2 = moment(when.endDateTime).format("MMM");

      const mesInicio = parseInt(moment(when.startDateTime).format("MM"));
      const mesFim = parseInt(moment(when.endDateTime).format("MM"));

      if (mesFim > mesInicio) {
        return `${inicio} de <b style="text-transform: capitalize;font-weight:normal;">${mes1}</b> a ${fim} de <b style="text-transform: capitalize;font-weight:normal;">${mes2}</b>`;
      } else {
        return `${inicio} a ${fim} de <b style="text-transform: capitalize;font-weight:normal;">${mes2}</b>`;
      }
    } else {
      return "";
    }
  };

  const Badge = () => {
    return (
      <>
        {!!data.completed && (
          <img src={Conquistado} alt="Conquistado" className="infoDesafio" />
        )}
        {(!!data.apurado || !!data.isApurado) && !data.completed && (
          <img src={Apurado} alt="Conquistado" className="infoDesafio" />
        )}
        {!data.vigente &&
        !data.completed &&
        !data.apurado &&
        !data.isApurado ? (
          <img src={Concluido} alt="Conquistado" className="infoDesafio" />
        ) : (
          <></>
        )}
        {data.vigente && data.novo && !data.completed ? (
          <img src={Novo} alt="Concluído" className="infoDesafio" />
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <>
      <div
        className={`challenge-card item text-white ${
          !data.vigente ? "expired" : ""
        }`}
        onClick={() => {
          showDetailChallenge(data);
          handleActionLog(data);
        }}
        title={data.challenge}
        style={
          {
            "--axis-color": data.eixo ? data.eixo.background : "#FFF",
            "--text-color": data.eixo ? data.eixo.color : "#FFF",
          } as React.CSSProperties
        }
      >
        <Badge />
        {data.eixo && data.eixo.image && (
          <div className={`image`}>
            <img
              src={data.eixo.image}
              alt={data.eixo.title ? data.eixo.title : ""}
              className="imageCardDesafios"
            />
          </div>
        )}
        {!data.eixo && (
          <div className={`image`}>
            <img className="imageCardDesafios" alt=""/>
          </div>
        )}
        <div className="content">
          <div className="wrapper">
            <span>{!!data.eixo ? data.eixo.title : ""}</span>
            {handleChallengeName(data.challenge)}
            <p
              dangerouslySetInnerHTML={{ __html: handleUntilDate(data.when) }}
            ></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChallengeCard;
