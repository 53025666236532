import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import ReactModal from "react-modal";

import badgeAnimation from "assets/lottie/trophy.json";
import trophy from "assets/img/trophy.svg";

import { useModal } from "context/ModalContext";
import { UserAvatar } from "components/user-avatar";
import { Button } from "flowbite-react";

import "./styles.scss";

export function AvatarChallengeModal() {
  const { user } = useAuth();
  const { openModal, modalData, showModal } = useModal();

  const modalID = "avatar-challenge";

  const [modalIsOpen, setIsOpen] = useState(false);

  ReactModal.setAppElement("#modal");

  function handleCloseModal() {
    setIsOpen(false);
  }

  function afterOpenModal() {
    //
  }

  function handleShowAvatar() {
    setIsOpen(false);
    showModal("avatar");
  }

  useEffect(() => {
    if (!user) return;
    setIsOpen(openModal === modalID);
  }, [user, openModal, modalData]);

  if (!user) return null;

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            <div className="bg-white rounded-xl p-3 flex flex-col items-center flex-none mx-5">
              <div className="flex items-center relative achievement-header px-2">
                <div className="relative">
                  {/* <Lottie animationData={badgeAnimation} loop={true} /> */}
                  <img src={trophy} alt="trophy" className="w-24" />
                </div>
                <div className="flex-1 leading-none text-lg text-blue-500 font-bold py-6 pr-6">
                  Crie seu avatar
                  {/* <br />e ganhe prêmios. */}
                </div>
              </div>

              <div
                className="relative w-[150px] h-[150px] rounded-full border border-gray-10 cursor-pointer"
                onClick={() => handleShowAvatar()}
              >
                <UserAvatar
                  data={{ ...user, ...user?.extra }}
                  responsive={true}
                />
              </div>

              <div className="py-3 relative z-10 w-full flex flex-col space-y-2">
                <Button type="button" onClick={() => handleShowAvatar()}>
                  CONFIGURAR AVATAR
                </Button>

                <Button
                  className="bg-blue-light"
                  type="button"
                  onClick={() => handleCloseModal()}
                >
                  FECHAR
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}
