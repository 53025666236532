import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";

import { useModal } from "context/ModalContext";
import { Button } from "flowbite-react";
import { Wheel } from "react-custom-roulette";
import { toast } from "react-toastify";

import "./styles.scss";
import {
  getAllRoletas,
  getRoleta,
  evaluateMystery,
  executeMystery,
} from "services/mystery.service";
import { getGlobalConfig } from "services/global.service";
import {
  getAllChallenges,
} from "services/challenges.service";
import { GiPadlock } from "react-icons/gi";
import { updateUserStatus } from "services/profile.service";

export function ModalRoleta() {
  const { user, refreshUser } = useAuth();
  const { openModal, modalData, showModal, closeModal } = useModal();
  const [roletas, setRoletas] = useState<any>([]);

  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState<any>();

  const [listOptions, setListOptions] = useState<any>([]);
  const [idRoleta, setIdRoleta] = useState<any>();

  const modalID = "modal-roleta";

  const [modalIsOpen, setIsOpen] = useState(false);

  const [statusRoleta, setStatuRoleta] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [isValidateSpin, setIsValidateSpin] = useState(false);

  const [requirements, setRequirements] = useState<any>([]);
  const [challenges, setChallenges] = useState<any>([]);
  const [titleRoleta, setTitleRoleta] = useState("");

  const Logo = "assets/logo-07.png";

  ReactModal.setAppElement("#modal");

  function handleCloseModal() {
    refreshUser();
    closeModal();
    setIdRoleta("");
    setRequirements([]);
    setListOptions([]);
  }

  function afterOpenModal() {  
    loadGlobalInfo()  
  }
  
  async function loadGlobalInfo() {
    const global = await getGlobalConfig("global");
    if(global[0].rouletteEnabled === false){
      toast.error(`❌ Roleta desabilitada temporariamente.`, {
        theme: "colored",
      });
      closeModal();
    }
  }

  useEffect(() => {
    if (user && openModal === modalID) {
      loadData();
    }    
    setIsOpen(openModal === modalID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, openModal, modalData]);

  async function loadData() {
    const data = await getAllRoletas();
    if(data.length > 0){
      setIdRoleta(data[0]._id);
      validateSpin(data[0]._id);

      let event = {target: {value: data[0]._id}}
      handleChange(event, false);
      setTitleRoleta(data[0].title);
      setRoletas(data);
    }
    
    const c = await getAllChallenges();
    setChallenges(c);
  }

  const validateSpin = (id: string) => {
    if (!id) return
    evaluateMystery(id, user?._id).then(function (res) {

      if (res.requirements.status === "UNAUTHORIZED") {
        toast.error(`❌ Recursos insuficientes para girar a roleta.`);
        setIsValidateSpin(false);
      }
      if (res.limit.status === "UNAUTHORIZED") {
        toast.error(`❌ Limite de tenativas atingido.`);
        setIsValidateSpin(false);
      }
      if (res.limit_wins.status === "UNAUTHORIZED") {
        toast.error(`❌ Limite de conquistas do prêmio atingido.`);
        setIsValidateSpin(false);
      }
      if (res.limit_attempts.status === "UNAUTHORIZED") {
        toast.error(`❌ Limite de tenativas atingido.`);
        setIsValidateSpin(false);
      }
      if (res.requirements.status === "OK") {
        setIsValidateSpin(true);
      }
        
    });
    // const newPrizeNumber = Math.floor(Math.random() * listOptions.length);
    //setPrizeNumber(newPrizeNumber);
    //setMustSpin(true);
  };

  const handleSpinClick = () => {
    setIsLoading(true);

    executeMystery(idRoleta, user?._id).then(function (res) {
      if (res.status === "WIN") {
        setStatuRoleta("win");

        const newPrizeNumber = findPosition(res.result[0]);
        setPrizeNumber(newPrizeNumber);
        setMustSpin(true);
      } else {
        setStatuRoleta("lose");

        const newPrizeNumber = findPosition(res.result[0]);
        setPrizeNumber(newPrizeNumber);
        setMustSpin(true);
      }
    });
  };

  const findPosition = (itemName: any) => {
    return listOptions.findIndex((item: any) => item.id === itemName);
  };

  function showMessage() {
    if (statusRoleta !== "" && statusRoleta === "win") {
      toast.success(`Parabens você ganhou`);
    } else if (statusRoleta !== "" && statusRoleta === "lose") {
      toast.error(`❌ Não foi desta vez, quem sabe na próxima =).`, {
        theme: "colored",
      });
    }
    setIsLoading(false);  
  }

  const colors0 = [
    "#FF0000",
    "#FFA500",
    "#FFFF00",
    "#008000",
    "#0000FF",
    "#800080",
    "#FFC0CB",
    "#A52A2A",
    "#FF1493",
    "#1E90FF",
  ];

  const colors = ["#6BC6F2", "#244588"];

  const handleChange = (event: any, loadValidade?: boolean) => {
    
    if(!!event.target.value){
      setIdRoleta(event.target.value);

      if(loadValidade !== false){
        validateSpin(event.target.value);
      }

      getRoleta(event.target.value).then(function (res) {
        const arr = [];

        setRequirements(res.requirements);

        for (let i = 0; i < res.options.length; i++) {
          const element = res.options[i];
          let obj = {
              option: "",
              image: null as { uri: string; sizeMultiplier: number; offsetY: number } | null,
              style: { backgroundColor: "", textColor: "" },
              id: "",
          };        
          obj.id = element.value;
          obj.option = element.title.toUpperCase();

          if(res.extra.theme === "colored"){
            obj.style.backgroundColor = colors0[i];            
            obj.style.textColor = "#000000";
          } else {
            obj.style.backgroundColor = i % 2 === 0 ? colors[0] : colors[1];
            obj.style.textColor = i % 2 === 0 ? "#244588": "#FFFFFF";
          }          
          
          if (element.image) {
            obj.image = { uri: element.image, sizeMultiplier: 0.8, offsetY: 130 }; 
          }  

          arr.push(obj);        
        }

        setListOptions(arr);
      });
    } else {      
      setIdRoleta("");
      setRequirements([]);
      setListOptions([]);
    }
  };
  
  const verificaTipoRequisito = (item: any) => {
    let text = ""
    if(item.type === 0 ){
      const point = item.item === "star" ? "Estrela" : "Giro"
      text = " Ponto(s) - " + point;
    } else if (item.type === 1){
      const desafio = challenges.filter((e: any)=> e._id === item.item)[0];
      text = " Desafio(s) - " + desafio ? desafio.challenge : "undefined";
    }
    return text;
  }

  if (!user) return null;

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-blue-dark backdrop-blur-sm bg-opacity-90 flex flex-col items-center justify-center"
        contentElement={() => (
          <div id="modalRoleta">
            <div className="bg-white rounded-xl p-3 flex flex-col items-center flex-none mx-5">
              <Button
                className="btnClose"
                type="button"
                onClick={() => handleCloseModal()}
              >
                X
              </Button>
              {roletas.length <= 1 && 
                <h3 className="text-2xl text-blue-500 font-bold mt-[40px]">
                  {titleRoleta}
                </h3>
              }

              <div className="md:w-[600px] flex flex-col md:flex-row space-y-16 md:space-x-6 md:space-y-0 md:items-start md:justify-center">
                <div className="flex-1 flex flex-col">
                  <div className="rounded overflow-hidden shadow-xl min-h-[210px] md:min-h-[640px] text-center">
                    {roletas.length > 1 && 
                      <select
                        value={idRoleta}
                        onChange={handleChange}
                        className="m-4"
                      >
                        <option value="">
                          Selecione
                        </option>
                        {roletas.map((item: any) => (
                          <option key={item._id} value={item._id}>
                            {item.title}
                          </option>
                        ))}
                      </select> 
                    }
                    {listOptions.length > 0 && (
                      <>
                        <Wheel
                          mustStartSpinning={mustSpin}
                          prizeNumber={prizeNumber}
                          data={listOptions}
                          outerBorderColor={"dimgray"}
                          innerBorderColor={"darkgray"}
                          radiusLineColor={"darkgray"}
                          outerBorderWidth={2}
                          radiusLineWidth={2}
                          fontSize={13}
                          perpendicularText={true}
                          textDistance={70}
                          fontWeight={"bold"}
                          onStopSpinning={() => {
                            setMustSpin(false);
                            showMessage();
                          }}
                        />
                        {isValidateSpin ? (<button
                          disabled={loading}
                          onClick={handleSpinClick}
                          className="bola"
                        >
                          {!loading ? "GIRAR" : "BOA SORTE!"}
                        </button>) : (
                          <button
                          className="bola locked flex items-center justify-center"
                        >
                          <GiPadlock />
                        </button>
                        )}
                        
                      </>
                    )}                    
                    {requirements && requirements.length > 0 && (
                      <div className="pl-5 text-left">
                        <p>Requisito(s) para jogar esta roleta:</p>
                        <ul className="pl-[30px] list-disc">
                          {requirements.map((item: any) => (
                            <li key={item._id}><strong>{item.total} {verificaTipoRequisito(item)}</strong></li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <img src={Logo} alt="logo" className="w-[250px] mx-auto my-8"/>
                  </div>
                </div>
                {/* <div className="w-full md:w-[36%]">
              <ShirtPicker />
            </div> */}
              </div>

              <div className="py-3 relative z-10 w-full flex flex-col space-y-2"></div>
            </div>
          </div>
        )}
      />
    </>
  );
}
