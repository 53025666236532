import { NavMenu } from 'components/navmenu';
import { UserInfo } from 'components/user-info';
import { PromoVideo } from 'components/video/promo';
import { ShirtPicker } from 'pages/Home/components/shirt-picker';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router';

export default function Achievements() {
  return (
    <>
      <Helmet>
        <title>Ganhadores - VAI PRA CIMA, BRB!</title>
      </Helmet>
      <div id="home-wrapper" className="min-h-screen">
        <div className="z-10 mx-auto relative flex flex-col items-center space-y-16 p-10">
          <UserInfo />
          <NavMenu />
          <div className="w-full max-w-[1024px] rounded bg-white p-6">
            <h1 className="text-4xl">Ganhadores</h1>
            <p>Em breve os resultados serão publicados nesta página.</p>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}
