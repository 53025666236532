import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";

import { useModal } from "context/ModalContext";
import achievementTitle from "assets/img/achievement-title.png";
import trophy from "assets/img/trophy.svg";

import { Button } from "flowbite-react";
import "./styles.scss";

export function PurchaseItemModal(props: any) {
  const { refreshUser, user } = useAuth();
  const { openModal, modalData, showModal, closeModal } = useModal();

  const modalID = "modal-purchase-item";

  const [modalIsOpen, setIsOpen] = useState(false);

  ReactModal.setAppElement("#modal");

  function handleCloseModal() {
    refreshUser();
    closeModal();
  }

  function afterOpenModal() {
    //
  }

  function handleShowAvatar() {
    //
  }

  useEffect(() => {
    if (!user) return;
    setIsOpen(openModal === modalID);
  }, [user, openModal, modalData]);

  if (!user) return null;

  if (!modalData || modalData?.length < 1) return null;

  const item = modalData;

  function Text() {
    if (item?.rewards?.[0]?.total && item.catalogId !== "cards_gemac") {
      if (item?.rewards?.[0]?.item !== "star") {
        return (
          <div className="text-gray-500 text-center mb-2 py-2 w-full">
            <p className="text-[18px] leading-tight py-2">
              Você recebeu os seus pontos mundo BRB. <br />
              Os <strong>{item.rewards[0].total}</strong> pontos serão
              creditados no Portal Mundo BRB no prazo máximo de{" "}
              <strong>30 dias</strong>.
            </p>
          </div>
        );
      } else {
        return (
          <div className="text-gray-500 text-center mb-2 py-2 w-full">
            <p className="text-[18px] leading-tight py-2">
              Os <strong>{item.rewards[0].total}</strong> pontos já foram
              creditados para você.
            </p>
          </div>
        );
      }
    } else if (item.type !== "unidade") {
      if (item.catalogId === "cards_gemac") {
        return (
          <div className="text-gray-500 text-center mb-2 py-2 w-full">
            <p className="text-[18px] leading-tight py-2">
              O prêmio abaixo poderá ser resgatado na GEMAC.
            </p>
          </div>
        );
      } else {
        return (
          <div className="text-gray-500 text-center mb-2 py-2 w-full">
            <p className="text-[18px] leading-tight py-2">
              A GEMAC entrará em contato com você.
            </p>
          </div>
        );
      }
    }

    if (item.type === "unidade" && item.isOwner) {
      if (item.catalogId === "cards_gemac") {
        return (
          <div className="text-gray-500 text-center mb-2 py-2 w-full">
            <p className="text-[18px] leading-tight py-2">
              O prêmio abaixo poderá ser resgatado na GEMAC.
            </p>
          </div>
        );
      } else {
        return (
          <div className="text-gray-500 text-center mb-2 py-2 w-full">
            <p className="text-[18px] leading-tight py-2">
              A GEMAC entrará em contato com a unidade.
            </p>
          </div>
        );
      }
    }

    if (item.type === "unidade" && !item.isOwner) {
      if (item.catalogId === "cards_gemac") {
        return (
          <div className="text-gray-500 text-center mb-2 py-2 w-full">
            <p className="text-[18px] leading-tight py-2">
              O prêmio abaixo poderá ser resgatado pelo responsavel da unidade
              na GEMAC.
            </p>
          </div>
        );
      }
    }
  }

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div id="modalPurchaseItem">
            <div className="bg-white rounded-xl p-3 py-5 flex flex-col items-center flex-none mx-5 min-w-[315px] md:w-[400px]">
              <Button
                type="button"
                onClick={() => handleCloseModal()}
                className="btnClose"
              >
                X
              </Button>
              <div className="text-center">
                <img
                  src={achievementTitle}
                  alt="Parabéns"
                  className="max-w-[250px] md:max-w-auto"
                />
              </div>

              {Text()}

              {item?.name && (
                <p className="text-[18px] text-orange-500 font-bold text-center">
                  {item?.name}
                </p>
              )}
              <div className="relative w-[200px]">
                {/* <Lottie animationData={badgeAnimation} loop={true} /> */}
                <img
                  src={trophy}
                  alt="Parabéns"
                  className="h-48 w-full object-contain"
                />
              </div>
              {/* <img src={achievementImage} alt="Parabéns" className="w-full" /> */}
              {/* <div className="py-3 relative z-10">
              </div> */}
            </div>
          </div>
        )}
      />
    </>
  );
}
