import { Helmet } from "react-helmet-async";

export default function Faq() {
  return (
    <>
      <Helmet>
        <title>Funifier Boilerplace - FAQ</title>
      </Helmet>
      <div>
        <h1>FAQ</h1>
      </div>
    </>
  );
}
