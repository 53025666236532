import { Button, Modal } from 'flowbite-react';
import React from 'react';
import { BiError } from 'react-icons/bi';

interface IConfirmationModalProps {
  show: boolean;
  dismissible?: boolean;
  onClose?: () => void;
  onConfirm: () => void;
  title?: string;
  message?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  icon?: React.ReactNode;
}

export function ConfirmationModal(props: IConfirmationModalProps) {
  const {
    show,
    dismissible,
    onClose,
    onConfirm,
    title = 'Are you sure?',
    message = 'Are you sure you want to perform this action?',
    confirmLabel = "Yes, I'm sure",
    cancelLabel = 'No, cancel',
  } = props;
  return (
    <Modal show={show} size="md" popup={true} onClose={onClose}>
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <div className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200">
            {props.icon ? props.icon : <BiError className="w-full h-full" />}
          </div>
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{message}</h3>
          <div className="flex justify-center gap-4">
            {dismissible ? (
              <Button color="failure" onClick={onClose}>
                {cancelLabel}
              </Button>
            ) : null}
            <Button color="gray" onClick={onConfirm}>
              {confirmLabel}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
