import { AxiosDefaults, AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { createTokenCookies, getRefreshToken, getToken, removeTokenCookies } from '../utils/tokenCookies';
import { api } from './api';

interface IFailedRequestQueue {
  onSuccess: (token: string) => void;
  onFailure: (error: AxiosError) => void;
}

let isRefreshing = false;
let failedRequestQueue: IFailedRequestQueue[] = [];

export function setAuthorizationHeader(request: AxiosDefaults | AxiosRequestConfig | any, token: string) {
  request.headers.Authorization = `Bearer ${token}`;
}

function handleRefreshToken(refreshToken: string) {
  isRefreshing = true;

  api
    .post('/refresh', { refreshToken })
    .then((response) => {
      const { token } = response.data;

      createTokenCookies(token, response.data.refreshToken);
      setAuthorizationHeader(api.defaults, token);

      failedRequestQueue.forEach((request) => request.onSuccess(token));
      failedRequestQueue = [];
    })
    .catch((error) => {
      failedRequestQueue.forEach((request) => request.onFailure(error));
      failedRequestQueue = [];

      removeTokenCookies();
    })
    .finally(() => {
      isRefreshing = false;
    });
}

function onRequest(config: AxiosRequestConfig): AxiosRequestConfig {
  
  const token = getToken();
  
  if (!config.headers) {
    config.headers = {};
  }

  config.headers['content-type'] = 'application/json';
  config.headers['cache-control'] = 'no-cache';

  if (!!config.url?.match(/(database\/registration__c)|(player\/password)|(player_register__c)|(player_validate__c)|(feedback__c)/g)) {
    config.headers.Authorization =
      'Basic ' + window.btoa(`${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_PUBLIC_SECRETKEY}`);
  } else {
    if (!!token) {
      setAuthorizationHeader(config, token);
    } else if (!!config.url?.match(/\/global/g)) {
      config.headers.Authorization =
        'Basic ' + window.btoa(`${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_PUBLIC_SECRETKEY}`);
    }
  }

  return config;
}

function onRequestError(error: AxiosError): Promise<AxiosError> {
  return Promise.reject(error);
}

function onResponse(response: AxiosResponse): AxiosResponse {
  return response;
}

function onResponseError(error: AxiosError): Promise<AxiosError | AxiosResponse> {
  if (
    error?.response?.status === 401 &&
    (!error?.response?.data as any).message?.match('you have exced the limit of times')
  ) {
    const errorResponse: any = error.response;
    if (errorResponse?.data?.code === 'token.expired') {
      const originalConfig = error.config;
      const refreshToken = getRefreshToken();

      !isRefreshing && handleRefreshToken(refreshToken);

      return new Promise((resolve, reject) => {
        failedRequestQueue.push({
          onSuccess: (token: string) => {
            setAuthorizationHeader(originalConfig, token);
            resolve(api(originalConfig));
          },
          onFailure: (error: AxiosError) => {
            reject(error);
          },
        });
      });
    } else {
      document.dispatchEvent(new CustomEvent('signout'));
    }
  }

  return Promise.reject(error);
}

export function setupInterceptors(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
