import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import ReactModal from "react-modal";
import { PromoVideo } from "components/video/promo";
import ReactPlayer from "react-player";
import { actionLog } from "services/actionlog.service";

import { useModal } from "context/ModalContext";
import { Button } from "flowbite-react";

import "./styles.scss";

export function VideoModal() {
  const { user } = useAuth();
  const { openModal, modalData, showModal, closeModal } = useModal();

  const modalID = "modal-video";

  const [modalIsOpen, setIsOpen] = useState(false);

  ReactModal.setAppElement("#modal");

  function handleCloseModal() {
    closeModal();
  }

  function afterOpenModal() {
    //
  }

  function handleShowAvatar() {
    //
  }

  useEffect(() => {
    if (!user) return;
    setIsOpen(openModal === modalID);
  }, [user, openModal, modalData]);

  if (!modalData || modalData?.length < 1) return null;

  const item = modalData.config;

  async function handleVideoEnded() {
    await actionLog("watch_video", { video: item.link });
  }

  if (!user) return null;

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-blue-dark backdrop-blur-sm bg-opacity-90 flex flex-col items-center justify-center"
        contentElement={() => (
          <div id="modalVideo">
            <div className="bg-white rounded-xl p-3 flex flex-col items-center flex-none mx-5">
              <Button
                className="btnClose"
                type="button"
                onClick={() => handleCloseModal()}
              >
                X
              </Button>
              <h3 className="text-lg text-blue-500 font-bold">
                Vídeo Campanha
              </h3>

              <div className="md:w-[1000px] flex flex-col md:flex-row space-y-16 md:space-x-6 md:space-y-0 md:items-start md:justify-center">
                <div className="flex-1 flex flex-col">
                  <div className="rounded overflow-hidden shadow-xl bg-black h-[210px] md:h-[560px]">
                    <ReactPlayer
                      url={item.link}
                      light={true}
                      controls={true}
                      width="100%"
                      height="100%"
                      config={{
                        youtube: {
                          playerVars: {
                            autoplay: 1,
                            rel: 0,
                          },
                        },
                      }}
                      onClickPreview={(e) => {
                        console.log("click preview", e);
                      }}
                      onEnded={() => {
                        handleVideoEnded();
                      }}
                    />
                  </div>
                  <h3 className="-order-1 md:order-1 text-center text-black text-xs md:text-md font-bold px-5 py-4 leading-tight">
                    {item.description} <br className="md:hidden" />
                    VAI PRA CIMA, BRB!
                  </h3>
                </div>
                {/* <div className="w-full md:w-[36%]">
              <ShirtPicker />
            </div> */}
              </div>

              <div className="py-3 relative z-10 w-full flex flex-col space-y-2"></div>
            </div>
          </div>
        )}
      />
    </>
  );
}
