import { api } from "./api";

export async function checkNotifications() {
  const { data } = await api.get(
    `notification?player=me&scope=private&published_min=-30d&max_results=10`
  );
  return data;
}

export async function getNotifications() {
  const { data } = await api.get(
    `notification?player=me&scope=private&published_min=-30d&max_results=100`
  );
  return data;
}

export async function clearNotifications() {
  const { data } = await api.get(
    "notification?player=me&scope=private&max_results=1&delete=true"
  );
  return data;
}

export async function getMyNotifications(id: string) {
  const { data } = await api.get(
    `database/notification?q=player.id:'${id}'&max_results=100`
  );
  return data;
}

export async function deleteNotification(id: string) {
  const { data } = await api.delete(`database/notification?q=_id:'${id}'`);
  return data;
}

export async function getAllNotifications(date?: any) {
  const payload = [{ $sort: { created: 1 } }] as any;

  if (date) {
    payload.unshift({
      $match: {
        created: {
          $gt: {
            $date: date,
          },
        },
      },
    });
  }

  const { data } = await api.post(
    `database/notifications__c/aggregate`,
    payload
  );
  if (!Array.isArray(data)) {
    return null;
  } else {
    return data[0];
  }
}

export async function getNotificationsByLogin(userId?: any) {
  const payload = [
      {
          "$match": {
              "actionId": "login",
              "userId": userId
          }
      },
      {
          "$sort": {
              "time": -1
          }
      },
      {
          "$limit": 1
      },
      {
          "$lookup": {
              "from": "player",
              "localField": "userId",
              "foreignField": "_id",
              "as": "p"
          }
      },
      {
          "$unwind": "$p"
      },
      {
          "$lookup": {
              "from": "achievement",
              "let": {
                  "player": "$userId",
                  "unidade": "$p.teams"
              },
              "pipeline": [
                  {
                      "$match": {
                          "$expr": {
                              "$or": [
                                  { "$eq": ["$player", "$$player"] },
                                  { "$in": ["$player", "$$unidade"] }
                              ]
                          },
                          "extra.isOpen": {
                              "$not": {
                                  "$elemMatch": {
                                      "$eq": userId
                                  }
                              }
                          }
                      }
                  },
                  {
                      "$addFields": {
                          "source": {
                              "$cond": {
                                  "if": { "$in": ["$player", "$$unidade"] },
                                  "then": "unidade",
                                  "else": "player"
                              }
                          }
                      }
                  },
                  {
                      "$project": {
                          "_id": 1,
                          "player": 1,
                          "total": 1,
                          "type": 1,
                          "item": 1,
                          "time": 1,
                          "extra": 1,
                          "source": 1
                      }
                  }
              ],
              "as": "achievement"
          }
      },
      {
          "$unwind": "$achievement"
      },
      {
          "$match": {
              "$expr": { "$lte": ["$achievement.time", "$time"] }
          }
      },
      {
          "$sort": {
              "achievement.time": -1
          }
      },
      {
          "$project": {
              "_id": 1,
              "actionId": 1,
              "userId": 1,
              "time": 1,
              "achievement": 1,
              "source": "$achievement.source"
          }
      },
      { "$match": {"achievement.total":{"$gt": 0}}}
  ];

  const { data } = await api.post(
    `database/action_log/aggregate?strict=true`,
    payload
  );
  if (!Array.isArray(data)) {
    return null;
  } else {
    return data[0];
  }
}
