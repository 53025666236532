import { api } from "./api";

export async function getExtratoSimples(
  player: string,
  startDate: string,
  endDate: string
) {
  const payload = { player, startDate, endDate };

  const { data } = await api.post("/find/extrato_simples", payload);
  return data;
}

export async function getApuracao(challenge: string) {
  const payload = { challenge };

  const { data } = await api.post("/find/apuracao", payload, { headers: { Range: 'items=0-5000' } });
  return data;
}
